import { Route } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import Calendly from './Components/Calendly.js'
import Form from './Components/ScheduleForm.js'
import Success from './Components/Success.js'
import Webintake from './Components/WebintakeForm.js'
import Listpage from './Components/listPage.js'
function App() {
  return (
    <>
      <Router>
        <Route path="/" exact component={Form} />
        <Route path="/calendlyview" exact component={Calendly} />
        <Route path="/form" exact component={Webintake} />
        <Route path="/success" exact component={Success} />
        <Route path="/list" exact component={Listpage} />

      </Router>
    </>
  );
}

export default App;
