import React from "react";
import footerlogo from "../assets/applogo.png";
import footerlogoapp from "../assets/apptext.png";
import beaconusvi from '../assets/beaconusvi.png'

export default function Footer() {

  return (
    <>
      <footer>
        <div className="footerHolder">
          <div className="birdlogo">
            <img src={beaconusvi} className="beaconlogo" />
          </div>

          <div className="copyRight">
            <span> Copyright &copy;{new Date().getFullYear()}</span>
            {"."}

            <span className="paddingADJF">
              Powered by{" "}
              <img className="footerimgadj widthLog" src={footerlogo} />
            </span>
            <span>
              <a href="http://www.hummingbird.health/" target="_blank">
                <img className="footerimgadj" src={footerlogoapp} />
              </a>
            </span>
          </div>
        </div>
      </footer>
    </>
  );
}
