import React, { useEffect, useRef } from "react";
import axios from "axios";
import "date-fns";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import DateFnsUtils from "@date-io/date-fns";
import { useHistory } from "react-router-dom";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import FormGroup from "@material-ui/core/FormGroup";
import "./Form.css";
import Modal from "@material-ui/core/Modal";
import bannerImage from "../assets/bannerImage.png";
import success from "../assets/success.png";
import close from "../assets/close.png";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Header from "./Header.js";
import Footer from "./Footer.js";
import RegisterBanner from "./RegisterBanner.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../Service/API/index.js";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
//Modal Box Material UI
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
// Material UI Global Styles
const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
  },
  input: {
    color: "#00AFB9",
  },
  paper: {
    position: "absolute",

    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: "20px",
  },
}));

//Material UI - Accordion styles
const Accordion = withStyles({
  root: {
    margin: "2em",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "2em",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "#fff",
    color: "#00AFB9",
    boxShadow: "0px 4px 9px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export default function Form() {
  //reference for scrolling
  const myRef = useRef(null);

  //State for race
  const [raced, setRaced] = React.useState([]);
  //State for Ethnicity
  const [RaceEthinicity, SetRaceEthinicity] = React.useState([]);
  //loading state
  const [isLoaded, setIsLoaded] = React.useState(false);

  //confirmation code state
  const [confirmationCode, setConfirmationCode] = React.useState("");
  const [getQRcode, setQrcode] = React.useState("");
  const [getpdfdownload, setpdfdownload] = React.useState("");
  const [labSiteName, setlabSiteName] = React.useState("");
  const [dob, setdob] = React.useState("");
  const [getdate, setdate] = React.useState("");
  const [gettime, settime] = React.useState("");
  const [firstName, setfirstName] = React.useState("");
  const [lastName, setlastName] = React.useState("");
  const [middleName, setmiddleName] = React.useState("");
  const [EmailAddress, setEmailAddress] = React.useState("");
  const [country, setCountry] = React.useState("US");
  const [countryList, setCountryList] = React.useState([]);
  const [city, setCity] = React.useState("");
  const [zipCode, setzipCode] = React.useState("");
  const [addressOne, setaddressOne] = React.useState("");
  const [addressTwo, setaddressTwo] = React.useState("");
  const [occupation, setoccupation] = React.useState("");
  //Temporary state for counties
  const [countyTemp, setCountyTemp] = React.useState([]);

  const [stateSelect, setstateSelect] = React.useState([]);

  //
  // Covid vaccine type state and OnChange
  const [Covid19vaccineType, setCovid19vaccineType] = React.useState("");
  const [Covid19vaccineTypeError, setCovid19vaccineTypeError] =
    React.useState("");

  const handleCovid19vaccineType = (event) => {
    setCovid19vaccineType(event.target.value);
  };

  //Dose Count State and OnChange
  const [doseCount, setdoseCount] = React.useState("");
  const [doseCountError, setdoseCountError] = React.useState("");
  const handleChangedoseCount = (event) => {
    setdoseCount(event.target.value);
  };

  //last dose date state and OnChange
  const [lastDoseDate, setlastDoseDate] = React.useState(null);
  const [lastDoseDateError, setlastDoseDateError] = React.useState(null);

  const handlelastDoseDate = (date) => {
    setlastDoseDate(date);
  };

  // ImmigrationStatus state and Handle Change
  const [immigrationStatus, setimmigrationStatus] = React.useState("");
  const [immigrationStatusError, setimmigrationStatusError] =
    React.useState("");
  const immigrationStatusChange = (event) => {
    setimmigrationStatus(event.target.value);
  };

  //======Get CountryList======//
  const GetCountryStateList = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "/bvi/country/list", {})
      .then((response) => {
        if (response) {
          setCountryList(response.data.info);
          // console.log(response.data.info);
        }
      })
      .catch((error) => {
        if (error.response) {
          toast(error.response.data);
        }
      });

    let data = {
      countryId: "233",
    };

    axios
      .post(
        process.env.REACT_APP_API_URL +
          "/bvi/state/list" +
          "?countryId=" +
          data.countryId
      )
      .then((response) => {
        if (response) {
          setformState({
            ...formstate,
            ["city"]: "",
          });
          setCity("");

          let allEntries = Object.entries(response.data.info.states);
          let sortedEntries = allEntries.sort((a, b) =>
            a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
          );
          setstateSelect(sortedEntries);
        }
      })
      .catch((error) => {
        if (error.response) {
          toast(error.response.data);
        }
      });

    let data1 = {
      stateId: "4966",
    };

    axios
      .post(
        process.env.REACT_APP_API_URL +
          "/bvi/city/list" +
          "?stateId=" +
          data1.stateId
      )
      .then((response) => {
        if (response) {
          let allEntries = Object.entries(response.data.info.cities);
          let sortedEntries = allEntries.sort((a, b) =>
            a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
          );
          setCountyTemp(sortedEntries);
        }
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.errorMessage);
        }
      });
  };

  useEffect(() => {
    // getRaces();
    axios
      .get(
        process.env.REACT_APP_API_URL +
          "/web/race/ethinicity/list" +
          "?labId=" +
          process.env.REACT_APP_LAB_ID,
        {
          headers: {
            Authorization: process.env.REACT_APP_ACCESS_TOKEN,
          },
        }
      )
      .then((res) => {
        setRaced(res.data.info.races);
        SetRaceEthinicity(res.data.info.ethinicitys);
      });

    if (localStorage.getItem("Form2")) {
      var setlocal = JSON.parse(localStorage.getItem("Form2"));
      if (setlocal.country) {
        axios
          .get(process.env.REACT_APP_API_URL + "/bvi/country/list", {})
          .then((response) => {
            if (response) {
              setCountryList(response.data.info);
            }
          })
          .catch((error) => {
            if (error.response) {
              toast(error.response.data);
            }
          });
      }

      let countryCodeValue = localStorage.getItem("CountryCode");
      let countrydata = {
        countryId: countryCodeValue.toString(),
      };

      axios
        .post(
          process.env.REACT_APP_API_URL +
          "/bvi/state/list" +
          "?countryId=" +
          countrydata.countryId
        )
        .then((response) => {
          if (response) {
            setformState({
              ...formstate,
              ["city"]: "",
            });           

            let allEntries = Object.entries(response.data.info.states);
            let sortedEntries = allEntries.sort((a, b) =>
              a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
            );
            setstateSelect(sortedEntries);
          }
        })
        .catch((error) => {
          if (error.response) {
            toast(error.response.data);
          }
        });
      setformState({
        //first accordion
        // firstName: setlocal.firstName,
        // middleName: setlocal.middleName,
        // lastName: setlocal.lastName,
        comments: setlocal.comments.replaceAll("''","'"),
        //second accordion

        // Third
        describeDisablity: setlocal.describeDisablity.replaceAll("''","'"),
      });

      setfirstName(setlocal.firstName.replaceAll("''","'"));
      setlastName(setlocal.lastName.replaceAll("''","'"));
      setmiddleName(setlocal.middleName.replaceAll("''","'"));
      setCountyTemp(JSON.parse(localStorage.getItem("countyTemp")));
      setEmailAddress(setlocal.email);
      setCity(setlocal.city);
      setzipCode(setlocal.zipCode.replaceAll("''","'"));
      setaddressOne(setlocal.addressOne.replaceAll("''","'"));
      setaddressTwo(setlocal.addressTwo.replaceAll("''","'"));
      setoccupation(setlocal.occupation.replaceAll("''","'"));
      setVaccinated(setlocal.receivedCovid19Vaccine);
      setSelectedDateSymptom(setlocal.symptomaticDate);
      setNumber(setlocal.mobileNumber);
      setCountry(setlocal.country);
      // setcloseContact(setlocal.setcloseContact)
      setSelectedDate(setlocal.dob);
      setsendMessageToMobileCheck(setlocal.sendMessageToMobile);
      setStateCheckNA(setlocal.email == "N/A" ? true : false);
      setCovid19vaccineType(setlocal.vaccineType);
      setGender(setlocal.gender);
      setEthnicity(setlocal.ethnicity);
      setCounty(setlocal.county);
      // setState(setlocal.state);
      setState(localStorage.getItem("State"));
      setRacedSelect(setlocal.race);
      setPregnancy(setlocal.pregnant);
      setMember(setlocal.prioritizedGroup);
      //
      setcloseContact(setlocal.closeContact);
      setSymptomatic(setlocal.symptomatic);
      setdummyarr(setlocal.symptoms);
      setStateCheck(setlocal.symptoms);

      // axios
      //   .get(process.env.REACT_APP_API_URL + "/web/dropdown/list", {
      //     headers: {
      //       Authorization: process.env.REACT_APP_ACCESS_TOKEN,
      //     },
      //   })
      //   .then((res) => {
      //     let a = Object.entries(res.data.info.states);
      //     let b;
      //     a.map((item) => {
      //       if (item[1] === setlocal.state) {
      //         b = item[0];
      //       }
      //     });
      //     console.log("a", b);
      //     setCountyTemp(setlocal.county ? res.data.info.counties[b] : []);
      //   });
    } else {
      GetCountryStateList();
    }
  }, []);

  // api calling for Race
  const getRaces = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "/web/dropdown/list", {
        headers: {
          Authorization: process.env.REACT_APP_ACCESS_TOKEN,
        },
      })
      .then((res) => {
        setCountyTemp(
          res.data.info.counties[
            process.env.REACT_APP_LAB_ID === "71" ||
            process.env.REACT_APP_LAB_ID === "72" ||
            process.env.REACT_APP_LAB_ID === "73" ||
            process.env.REACT_APP_LAB_ID === "74" ||
            process.env.REACT_APP_LAB_ID === "75" ||
            process.env.REACT_APP_LAB_ID === "76"
              ? "British Virgin Islands"
              : "Virgin Islands"
          ]
        );
        setstateSelect(Object.entries(res.data.info.states));
      });
  };

  const classes = useStyles();

  //Form Initialization
  const initialFormData = Object.freeze({
    //first accordion
    // firstName: "",
    // middleName: "",
    // lastName: "",
    mobileNumber: "",
    email: ""
  });

  // form state

  const [formstate, setformState] = React.useState(initialFormData);

  //Panel state - Accordion
  const [expanded, setExpanded] = React.useState("panel1");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  //move panel
  const [active1, setActive1] = React.useState(false);
  const [active2, setActive2] = React.useState(false);

  //symptom date state and Handle change
  const [selectedDateSymptom, setSelectedDateSymptom] = React.useState(null);
  const [dummyarr, setdummyarr] = React.useState([]);

  const handleDateChangeSymptom = (date) => {
    setSelectedDateSymptom(date);
  };

  //checkbox symptom initialization

  const initialFormData1 = Object({
    Shortness_of_breath: false,
    Cough: false,
    Chills: false,
    Fever: false,
    Headache: false,
    Difficulty_breathing: false,
    Fatigue: false,
    Diarrhea: false,
    Vomiting: false,
    Sore_Throat: false,
    Nausea: false,
    Loss_of_taste: false,
    Body_and_or_muscle_aches: false,
    Congestion_and_or_runny_nose: false,
  });

  //Checkbox symptom state and Handle Change

  const [stateChecked, setStateCheck] = React.useState(initialFormData1);

  const handleChangeCheck = (event) => {
    setStateCheck({
      ...stateChecked,
      [event.target.name]: event.target.checked,
    });
  };

  // pregnancy radio state and handle change
  const [pregnancy, setPregnancy] = React.useState("U");
  const handleChangeFormPregnancy = (event) => {
    setPregnancy(event.target.value);
  };

  const [symptomatic, setSymptomatic] = React.useState("");
  const handleSymptomatic = (event) => {
    setSymptomatic(event.target.value);

    if (event.target.value === "N") {
      setSelectedDateSymptom(null);
      setStateCheck(initialFormData1);
    }
  };

  const [closeContact, setcloseContact] = React.useState("");
  const handlecloseContact = (event) => {
    setcloseContact(event.target.value);
  };
  const [vaccinated, setVaccinated] = React.useState("");
  const handleVaccinated = (event) => {
    setVaccinated(event.target.value);

    if (event.target.value === "N") {
      setCovid19vaccineType("");
      setdoseCount("");
      setlastDoseDate(null);
    }
  };

  const moveNext = () => {
    if (firstName !== "") {
      updateFirstErrorData("");
    }

    if (lastName !== "") {
      updateLastErrorData("");
    }

    if (newnumber.slice(dialCode.length) !== "") {
      updateMobileErrorData("");
    }

    if (EmailAddress !== "") {
      updateEmailErrorData("");
    }

    if (addressOne !== "") {
      updateAddress("");
    }
    if (country !== "") {
      updateCountry("");
    }
    if (city !== "") {
      updateCity("");
    }
    if (zipCode !== "") {
      updateZip("");
    }

    if (state !== "") {
      updateState("");
    }
    if (county !== "") {
      updateCounty("");
    }
    if (
      (process.env.REACT_APP_LAB_ID === "71" ||
        process.env.REACT_APP_LAB_ID === "72" ||
        process.env.REACT_APP_LAB_ID === "73" ||
        process.env.REACT_APP_LAB_ID === "74" ||
        process.env.REACT_APP_LAB_ID === "75" ||
        process.env.REACT_APP_LAB_ID === "76") &&
      (immigrationStatus !== null || immigrationStatus !== "")
    ) {
      setimmigrationStatusError("");
    }
    if (stateCheckedNA !== "") {
      updateEmailErrorData("");
    }

    //validations
    if (
      firstName.trim() == "" &&
      firstName.trim().length == 0
    ) {
      updateFirstErrorData("Please enter first name");
      myRef.current.scrollIntoView();
    } else if (
      lastName.trim() == "" &&
      lastName.trim().length == 0
    ) {
      updateLastErrorData("Please enter last name");
      myRef.current.scrollIntoView();
    } else if (
      newnumber.slice(dialCode.length) === "" &&
      newnumber.slice(dialCode.length).length === 0
    ) {
      updateMobileErrorData("Please enter mobile number");
      myRef.current.scrollIntoView();
    } else if (newnumber.slice(dialCode.length).length < 8) {
      updateMobileErrorData("Please enter valid mobile number");
      myRef.current.scrollIntoView();
    }
    // else if (newnumber.length !== 11) {
    //     updateMobileErrorData("Please enter 10 digit mobile number");
    //     myRef.current.scrollIntoView();
    // }
    else if (
      (stateCheckedNA === false &&
        EmailAddress.trim() == "" &&
        EmailAddress.trim().length == 0) ||
      stateCheckedNA === ""
    ) {
      updateEmailErrorData("Please enter email id");
      myRef.current.scrollIntoView();
    } else if (
      stateCheckedNA === false &&
      !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(
        EmailAddress
      ) &&
      EmailAddress != ""
    ) {
      updateEmailErrorData("Please enter email id in correct format");
      myRef.current.scrollIntoView();
    } else if (
      addressOne.trim() == "" &&
      addressOne.trim().length == 0
    ) {
      updateAddress("Please enter address");
      myRef.current.scrollIntoView();
    } else if (country.trim() == "" && country.trim().length == 0) {
      updateCountry("Please select country");
      myRef.current.scrollIntoView();
    } else if (state.trim() == "" && state.trim().length == 0) {
      updateState("Please select state");
      myRef.current.scrollIntoView();
    } else if (
      zipCode.trim() == "" &&
      zipCode.trim().length == 0
    ) {
      updateZip("Please enter zip code");
      myRef.current.scrollIntoView();
    } else if (
      zipCode.trim().length < 5 ||
      zipCode.trim().length > 7
    ) {
      updateZip(
        "Zip code should be minimum 5 characters and maximum 7 characters"
      );
      myRef.current.scrollIntoView();
    } else if (
      city.trim() == "" &&
      city.trim().length == 0 &&
      countyTemp.length === 0
    ) {
      updateCity("Please enter city");
      myRef.current.scrollIntoView();
      
    } else if (countyTemp.length && county.trim() == "" && county.trim().length == 0) {
      updateCounty("Please select county");
      myRef.current.scrollIntoView();
    } else if (
      (process.env.REACT_APP_LAB_ID === "71" ||
        process.env.REACT_APP_LAB_ID === "72" ||
        process.env.REACT_APP_LAB_ID === "73" ||
        process.env.REACT_APP_LAB_ID === "74" ||
        process.env.REACT_APP_LAB_ID === "75" ||
        process.env.REACT_APP_LAB_ID === "76") &&
      (immigrationStatus == null || immigrationStatus == "")
    ) {
      setimmigrationStatusError("Please select the immigration status");
      myRef.current.scrollIntoView();
    } else {
      setExpanded("panel2");
      setActive1(true);

      myRef.current.scrollIntoView();
    }
  };

  //move next panel

  const moveNext1 = () => {
    console.log(
      moment(selectedDate).format("MM/DD/yyyy"),
      moment(new Date()).format("MM/DD/yyyy")
    );
    if (
      selectedDate != "Invalid Date" ||
      moment(selectedDate).format("yyyy") <= "1899" == false ||
      selectedDate > new Date() == false
    ) {
      updateDateofBirth("");
      myRef.current.scrollIntoView();
      // return;
    }
    if (racedSelect !== "") {
      updateRace("");
    }
    if (gender !== "") {
      updateGender("");
    }

    if (member !== "") {
      seterrorMember("");
    }
    if (ethnicity !== "") {
      updateErrorEthnicity("");
    }

    //validations
    if (selectedDate == null || selectedDate == "") {
      updateDateofBirth("Please select date of birth");
      myRef.current.scrollIntoView();
    } else if (
      selectedDate == "Invalid Date" ||
      moment(selectedDate).format("yyyy") <= "1899" == true ||
      selectedDate > new Date() == true
    ) {
      updateDateofBirth("Please enter valid  DOB Date");
      myRef.current.scrollIntoView();
      // return;
    } else if (racedSelect == "") {
      updateRace("Please select Race");
      myRef.current.scrollIntoView();
    } else if (gender == "" && gender.length == 0) {
      updateGender("Please select gender");
      myRef.current.scrollIntoView();
    } else if (ethnicity == null || ethnicity == "") {
      updateErrorEthnicity("Please select Ethnicity");
      myRef.current.scrollIntoView();
    } else if (member == "") {
      seterrorMember("Please select patient group");
      myRef.current.scrollIntoView();
    } else if (member !== "" && occupation === "") {
      seterrorMember("Please enter occupation");
      myRef.current.scrollIntoView();
    } else {
      //uncomment
      setExpanded("panel3");

      // setExpanded("panel4");
      setActive2(true);
      myRef.current.scrollIntoView();
    }
  };

  // Whole form Textfield Handle Change event
  const handleChangeForm = (event) => {
    const name = event.target.name;
    setformState({
      ...formstate,
      [name]: event.target.value,
    });
  };

  // Mobile Number Handle Change
  const [newnumber, setNumber] = React.useState("");
  const [dialCode, setdialCode] = React.useState("");

  const mobileNumberChange = (number, e) => {
    setdialCode(e.dialCode);
    setNumber(number);
  };

  //Gender state and select Handle Change
  const [gender, setGender] = React.useState("");

  const handleChangeGender = (event) => {
    setGender(event.target.value);
  };

  //state select
  const [state, setState] = React.useState(
    process.env.REACT_APP_LAB_ID === "71" ||
      process.env.REACT_APP_LAB_ID === "72" ||
      process.env.REACT_APP_LAB_ID === "73" ||
      process.env.REACT_APP_LAB_ID === "74" ||
      process.env.REACT_APP_LAB_ID === "75" ||
      process.env.REACT_APP_LAB_ID === "76"
      ? "BVI,British Virgin Islands"
      : "VI,Virgin Islands"
  );

  const handleChangeCountry = (event) => {
    setCountry(event.target.value);
    setCountyTemp([]);
    countryList.map((item) => {
      if (item.countryCode === event.target.value) {        
        let data = {
          countryId: item.id,
        };
        console.log(data);
        axios
          .post(
            process.env.REACT_APP_API_URL +
              "/bvi/state/list" +
              "?countryId=" +
              data.countryId
          )
          .then((response) => {
            if (response) {
              setState("");
              setCity("");
              setCounty("");
              let allEntries = Object.entries(response.data.info.states);
              let sortedEntries = allEntries.sort((a, b) =>
                a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
              );
              setstateSelect(sortedEntries);
            }
          })
          .catch((error) => {
            if (error.response) {
              toast.error(error.response.errorMessage);
            }
          });
      }
    });
  };

  // Load county based on state
  const handleChangeState = (event) => {
    let check1 = event.target.value.split(",");
    setState(event.target.value);
    setCity("");
    setCounty("");

    stateSelect.map((item) => {
      if (item[0] == check1[1]) {
        let data = {
          stateId: item[1].id,
        };
        axios
          .post(
            process.env.REACT_APP_API_URL +
              "/bvi/city/list" +
              "?stateId=" +
              data.stateId
          )
          .then((response) => {
            if (response) {
              let allEntries = Object.entries(response.data.info.cities);
              let sortedEntries = allEntries.sort((a, b) =>
                a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
              );
              setCountyTemp(sortedEntries);
            }
          })
          .catch((error) => {
            if (error.response) {
              toast.error(error.response.errorMessage);
            }
          });
      }
    });
  };

  //County State and Handle Change
  // const [county, setCounty] = React.useState("St. Croix");

  const [county, setCounty] = React.useState(process.env.REACT_APP_COUNTY_NAME);

  const countyChange = (event) => {
    setCounty(event.target.value);
  };

  //Ethnicity radio
  const [ethnicity, setEthnicity] = React.useState("");
  const handleChangeEthnicity = (event) => {
    setEthnicity(event.target.value);
  };

  //memberGroup state and Handle Change
  const [member, setMember] = React.useState("");
  const handleChangeMember = (event) => {
    setMember(event.target.value);
  };

  //date of birth date state and Handle change
  const [selectedDate, setSelectedDate] = React.useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  //Not Applicable state and Handle Change

  const [stateCheckedNA, setStateCheckNA] = React.useState(false);
  const handleChangeNA = (event) => {
    setStateCheckNA(event.target.checked);
  };

  //send mobile chekcox

  const [sendMessageToMobileCheck, setsendMessageToMobileCheck] =
    React.useState(true);

  const handleChangeMobileCheck = (event) => {
    setsendMessageToMobileCheck(event.target.checked);
  };

  //checkbox secondary insurance

  const [newstateChecked, newsetStateCheck] = React.useState(false);

  const handleChangeCheckInsurance = (event) => {
    newsetStateCheck(event.target.checked);
  };

  //race handle change and state
  const [racedSelect, setRacedSelect] = React.useState("");

  const handleChangeRace = (event) => {
    setRacedSelect(event.target.value);
  };

  //modal box open state
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  //modal box close
  const handleClose = () => {
    setOpen(false);
    window.location.reload();
  };

  //modal box body
  const body = (
    <div style={modalStyle} className="paperModal">
      <div className="closeHolder" onClick={handleClose}>
        <img src={close} />
      </div>
      <div className="content">
        <div className="rander">
          <img src={bannerImage} className="popImage" />
        </div>
        <div className="center">
          <img src={success} className="popImage1" />
        </div>
      </div>

      <div className="successmsg">
        <div style={{ fontSize: "35px", marginTop: "15px" }}>
          Patient registered successfully
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div>
            <img src={getQRcode} style={{ width: "160px" }} />
          </div>
          <div style={{ marginTop: "120px" }}>
            <a href={getpdfdownload} target="_blank">
              <i className="fa fa-download"></i>
            </a>
          </div>
        </div>

        <table style={{ display: "inline-grid", paddingBottom: "1.5em" }}>
          <tr style={{ fontSize: "20px" }}>
            <td>
              <div style={{ color: "#f07167" }}>Confirmation Code:</div>
            </td>
            <td>
              <div style={{ color: "#00afb9" }}>{confirmationCode}</div>
            </td>
          </tr>
          <tr style={{ fontSize: "20px" }}>
            <td>
              <div style={{ color: "#f07167" }}>Name:</div>
            </td>
            <td>
              <div style={{ color: "#00afb9" }}>
                {firstName} {lastName}
              </div>
            </td>
          </tr>
          <tr style={{ fontSize: "20px" }}>
            <td>
              <div style={{ color: "#f07167" }}>DOB:</div>
            </td>
            <td>
              <div style={{ color: "#00afb9" }}>{dob}</div>
            </td>
          </tr>
          <tr style={{ fontSize: "20px" }}>
            <td>
              <div style={{ color: "#f07167" }}>Lab Site:</div>
            </td>
            <td>
              <div style={{ color: "#00afb9" }}>{labSiteName}</div>
            </td>
          </tr>
          <tr style={{ fontSize: "20px" }}>
            <td>
              <div style={{ color: "#f07167" }}>
                Appointment Created Date and Time(AST):
              </div>
            </td>
            <td>
              <div style={{ color: "#00afb9" }}>
                {getdate} - {gettime}
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  );

  //Error States

  const [errorFirstData, updateFirstErrorData] = React.useState();
  const [errorLastData, updateLastErrorData] = React.useState();
  const [errorMobileData, updateMobileErrorData] = React.useState();
  const [errorEmailData, updateEmailErrorData] = React.useState();
  const [errorAddress, updateAddress] = React.useState();
  const [errorCity, updateCity] = React.useState();
  const [errorCountry, updateCountry] = React.useState();
  const [errorCounty, updateCounty] = React.useState();
  const [errorZip, updateZip] = React.useState();
  const [errorOccupation, updateOccupation] = React.useState();
  const [errorRace, updateRace] = React.useState();
  const [errorGender, updateGender] = React.useState();
  const [errorState, updateState] = React.useState();
  const [errorStateChecked, updateStateChecked] = React.useState();

  const [errorDateofbirth, updateDateofBirth] = React.useState();
  const [errorSymptomDate, updateSymptomDate] = React.useState();
  const [errorSymptoms, updateSymptoms] = React.useState();

  const [errorMember, seterrorMember] = React.useState();
  const [errorEthnicity, updateErrorEthnicity] = React.useState();
  const [errorSymptomatic, updateErrorSymptomatic] = React.useState();
  const [errorcloseContact, updateErrorcloseContact] = React.useState();

  const [errorVaccinated, updateErrorVaccinated] = React.useState();

  const formSubmit = (e) => {

    updateFirstErrorData("");
    updateLastErrorData("");
    updateMobileErrorData("");
    updateEmailErrorData("");
    updateAddress("");
    updateCity("");
    updateCountry("");
    updateCounty("");
    updateZip("");
    updateOccupation("");
    updateRace("");
    updateGender("");
    updateState("");
    updateStateChecked("");
    updateDateofBirth("");
    updateSymptomDate("");
    updateSymptoms("");
    seterrorMember("");
    updateErrorEthnicity("");
    updateErrorSymptomatic("");
    updateErrorcloseContact("");
    updateErrorVaccinated("");

    if (
      selectedDate != "Invalid Date" ||
      moment(selectedDate).format("yyyy") <= "1899" == false ||
      selectedDate > new Date() == false
    ) {
      updateDateofBirth("");
      myRef.current.scrollIntoView();
      // return;
    }

    if (firstName !== "") {
      updateFirstErrorData("");
    }

    if (lastName !== "") {
      updateLastErrorData("");
    }

    if (newnumber.slice(dialCode.length) !== "") {
      updateMobileErrorData("");
    }

    if (EmailAddress !== "") {
      updateEmailErrorData("");
    }

    if (addressOne !== "") {
      updateAddress("");
    }
    if (country !== "") {
      updateCountry("");
    }

    if (city !== "") {
      updateCity("");
    }
    if (zipCode !== "") {
      updateZip("");
    }

    if (racedSelect !== "") {
      updateRace("");
    }
    if (gender !== "") {
      updateGender("");
    }
    if (state !== "") {
      updateState("");
    }
    if (county !== "") {
      updateCounty("");
    }
    if (
      (process.env.REACT_APP_LAB_ID === "71" ||
        process.env.REACT_APP_LAB_ID === "72" ||
        process.env.REACT_APP_LAB_ID === "73" ||
        process.env.REACT_APP_LAB_ID === "74" ||
        process.env.REACT_APP_LAB_ID === "75" ||
        process.env.REACT_APP_LAB_ID === "76") &&
      immigrationStatus !== ""
    ) {
      setimmigrationStatusError("");
    }
    if (selectedDate !== null) {
      updateDateofBirth("");
    }

    if (member !== "") {
      seterrorMember("");
    }
    if (ethnicity !== "") {
      updateErrorEthnicity("");
    }

    if (
      Object.keys(stateChecked).filter((e) => stateChecked[e] === true).length >
        0 &&
      selectedDateSymptom !== null
    ) {
      updateSymptomDate("");
      myRef.current.scrollIntoView();
    }
    if (
      Object.keys(stateChecked).filter((e) => stateChecked[e]).length === true
    ) {
      updateSymptoms("");
      myRef.current.scrollIntoView();
    }

    if (localStorage.getItem("Form1") === null && symptomatic !== "") {
      updateErrorSymptomatic("");
      myRef.current.scrollIntoView();
    }
    if (localStorage.getItem("Form1") === null && closeContact !== "") {
      updateErrorcloseContact("");
      myRef.current.scrollIntoView();
    }

    if (vaccinated !== null || vaccinated !== "") {
      updateErrorVaccinated("");
      myRef.current.scrollIntoView();
    }
    if (
      process.env.REACT_APP_LAB_ID === "71" ||
      process.env.REACT_APP_LAB_ID === "72" ||
      process.env.REACT_APP_LAB_ID === "73" ||
      process.env.REACT_APP_LAB_ID === "74" ||
      process.env.REACT_APP_LAB_ID === "75" ||
      process.env.REACT_APP_LAB_ID === "76"
    ) {
      if (
        (vaccinated == "Y" && Covid19vaccineType !== null) ||
        (vaccinated == "Y" && Covid19vaccineType !== "")
      ) {
        setCovid19vaccineTypeError("");
        myRef.current.scrollIntoView();
      }
      if (
        vaccinated == "Y" &&
        Covid19vaccineType !== "Janssen" &&
        doseCount !== ""
      ) {
        setdoseCountError("");
        myRef.current.scrollIntoView();
      }
      if (vaccinated == "Y" && lastDoseDate !== null) {
        setlastDoseDateError("");
        myRef.current.scrollIntoView();
      }
    }
    //validations

    if (firstName == "" && firstName.length == 0) {
      updateFirstErrorData("Please enter first name");
      myRef.current.scrollIntoView();
    } else if (lastName == "" && lastName.length == 0) {
      updateLastErrorData("Please enter last name");
      myRef.current.scrollIntoView();
    } else if (
      newnumber.slice(dialCode.length) === "" &&
      newnumber.slice(dialCode.length).length === 0
    ) {
      updateMobileErrorData("Please enter mobile number");
      myRef.current.scrollIntoView();
    } else if (newnumber.slice(dialCode.length).length < 8) {
      updateMobileErrorData("Please enter valid mobile number");
      myRef.current.scrollIntoView();
    } else if (
      stateCheckedNA === false &&
      EmailAddress == "" &&
      EmailAddress.length == 0
    ) {
      updateEmailErrorData("Please enter email id");
      myRef.current.scrollIntoView();
    } else if (addressOne == "" && addressOne.length == 0) {
      updateAddress("Please enter address");
      myRef.current.scrollIntoView();
    } else if (country.trim() == "" && country.trim().length == 0) {
      updateCountry("Please select country");
      myRef.current.scrollIntoView();
    } else if (state.trim() == "" && state.trim().length == 0) {
      updateState("Please select state");
      myRef.current.scrollIntoView();
    } else if (zipCode == "" && zipCode.length == 0) {
      updateZip("Please enter zip code");
      myRef.current.scrollIntoView();
    } else if (
      zipCode.trim().length < 5 ||
      zipCode.trim().length > 7
    ) {
      updateZip(
        "Zip code should be minimum 5 characters and maximum 7 characters"
      );
      myRef.current.scrollIntoView();
    } else if (
      !countyTemp.length &&
      city == "" &&
      city.length == 0     
    ) {
      updateCity("Please enter city");
      myRef.current.scrollIntoView();
    } else if (countyTemp.length && county.trim() == "" && county.trim().length == 0) {
      updateCounty("Please select county");
      myRef.current.scrollIntoView();
    } else if (
      (process.env.REACT_APP_LAB_ID === "71" ||
        process.env.REACT_APP_LAB_ID === "72" ||
        process.env.REACT_APP_LAB_ID === "73" ||
        process.env.REACT_APP_LAB_ID === "74" ||
        process.env.REACT_APP_LAB_ID === "75" ||
        process.env.REACT_APP_LAB_ID === "76") &&
      immigrationStatus == ""
    ) {
      setimmigrationStatusError("Please select the immigration status");
      myRef.current.scrollIntoView();
    } else if (selectedDate == null || selectedDate == "") {
      updateDateofBirth("Please select date of birth");
      myRef.current.scrollIntoView();
    } else if (
      selectedDate == "Invalid Date" ||
      moment(selectedDate).format("yyyy") <= "1899" == true ||
      selectedDate > new Date() == true
    ) {
      updateDateofBirth("Please enter valid  DOB Date");
      myRef.current.scrollIntoView();
      return;
    } else if (racedSelect == "") {
      updateRace("Please select Race");
      myRef.current.scrollIntoView();
    } else if (gender == "" && gender.length == 0) {
      updateGender("Please select gender");
      myRef.current.scrollIntoView();
    } else if (ethnicity == null || ethnicity == "") {
      updateErrorEthnicity("Please select Ethnicity");
      myRef.current.scrollIntoView();
    } else if (member == "") {
      seterrorMember("Please select patient groups");
      myRef.current.scrollIntoView();
    } else if (member === "Others" && occupation === "") {
      seterrorMember("Please enter occupation");
      myRef.current.scrollIntoView();
      return;
    } else if (
      localStorage.getItem("Form1") === null &&
      (symptomatic == null || symptomatic == "")
    ) {
      updateErrorSymptomatic(
        "Please select if you experience any Covid -19 symptoms?"
      );
      myRef.current.scrollIntoView();
      return;
    } else if (
      symptomatic === "Y" &&
      Object.keys(stateChecked).filter((e) => stateChecked[e]).length === true
    ) {
      updateSymptoms("Please select Symptoms");
      myRef.current.scrollIntoView();
      return;
    } else if (
      Object.keys(stateChecked).filter((e) => stateChecked[e] === true).length >
        0 &&
      selectedDateSymptom == null
    ) {
      updateSymptomDate("Please select Symptoms Date");
      myRef.current.scrollIntoView();
      return;
    } else if (localStorage.getItem("Form1") === null && closeContact === "") {
      updateErrorcloseContact("Please select the below question");
      myRef.current.scrollIntoView();
      return;
    } else if (vaccinated == null || vaccinated == "") {
      updateErrorVaccinated(
        "Please select if you received the Covid-19 vaccine?"
      );
      myRef.current.scrollIntoView();
      return;
    } else if (
      process.env.REACT_APP_LAB_ID === "71" ||
      process.env.REACT_APP_LAB_ID === "72" ||
      process.env.REACT_APP_LAB_ID === "73" ||
      process.env.REACT_APP_LAB_ID === "74" ||
      process.env.REACT_APP_LAB_ID === "75" ||
      process.env.REACT_APP_LAB_ID === "76"
    ) {
      console.log("CEHCK", doseCount, lastDoseDate);
      if (vaccinated == "Y" && Covid19vaccineType == "") {
        setCovid19vaccineTypeError("Please select COVID-19 vaccine type ");
        myRef.current.scrollIntoView();
        return;
      } else if (
        vaccinated == "Y" &&
        Covid19vaccineType !== "Janssen" &&
        doseCount == ""
      ) {
        setdoseCountError("Please select COVID-19 vaccine dose count ");
        myRef.current.scrollIntoView();
        return;
      } else if (vaccinated == "Y" && lastDoseDate == null) {
        setlastDoseDateError("Please select last dose date ");
        myRef.current.scrollIntoView();
        return;
      }
    }

    else if (
      firstName !== "" &&
      lastName !== "" &&
      newnumber !== "" &&
      addressOne !== "" &&
      //   formstate.city !== "" &&
      zipCode !== "" &&
      // ((process.env.REACT_APP_LAB_ID === "71" || process.env.REACT_APP_LAB_ID === "72" ||process.env.REACT_APP_LAB_ID === "73" ||process.env.REACT_APP_LAB_ID === "74") && immigrationStatus !== "")
      // &&
      member !== "" &&
      gender !== "" &&
      country !== "" &&
      state !== "" &&
      selectedDate !== null &&
      racedSelect !== "" &&
      ethnicity != ""
      //  &&
      // ((process.env.REACT_APP_LAB_ID === "71" || process.env.REACT_APP_LAB_ID === "72" ||process.env.REACT_APP_LAB_ID === "73" ||process.env.REACT_APP_LAB_ID === "74") && vaccinated == "Y" && (Covid19vaccineType == "" || (Covid19vaccineType !== "Janssen" && doseCount == "") || lastDoseDate === null))
    ) {
      var finaldateDob = moment(selectedDate).format("yyyy-MM-DD");
      alertbtn(firstName.trim().replaceAll("''","'"),lastName.trim().replaceAll("''","'"),finaldateDob,newnumber,EmailAddress);
    }
  };

  //===Pop Up modal functionalities===//
  const alertbtn = (Fname, Lname, DOB, Phone, Email) => {
    confirmAlert({
      title: 'Please confirm your details',
      message: `Please confirm that your information is correct. Your name and date of birth should be exactly as it appears on your photo ID. This information will be on your result report. Also confirm your phone number and email are valid to receive your result notification.`,
      childrenElement: () => <div style={{ display: "flex", flexDirection: "column", margin: "5px 0px" }}>
        <div style={{ margin: "5px 0px" }}>First Name:<span className="Status_name">{Fname}</span></div>
        <div style={{ margin: "5px 0px" }}>Last Name:<span className="Status_name">{Lname}</span></div>
        <div style={{ margin: "5px 0px" }}>DOB (MMM DD, YYYY):<span className="Status_name">{moment(DOB).format("MMM DD, YYYY")}</span></div>
        <div className="modalPhoneNumber" style={{ margin: "-3px 0px", display: "flex", alignItems: "center" }}><div>Phone:</div>
          <PhoneInput
            country={"us"}
            disabled={true}
            required
            name="mobileNumber"
            disableDropdown={true}
            value={Phone}
          /></div>
        <div style={{ margin: "5px 0px" }}>Email:<span className="Status_name">{Email ? Email : "N/A"}</span></div>
        <div style={{ margin: "5px 0px" }}>By clicking confirm, you agree to receiving a text/email notification with your appointment confirmation details.</div>
      </div>,
      buttons: [
        {
          label: 'Confirm',
          onClick: () => confirmfun()
        },
        {
          label: 'Cancel',
          onClick: () => cancelfun()
        }
      ],
    })
  };

  const confirmfun = () => {
    var finaldateDob = moment(selectedDate).format("yyyy-MM-DD");
    if (selectedDateSymptom != null) {
      var finalselectedDateSymptom =
        moment(selectedDateSymptom).format("yyyy-MM-DD");
    }
    let splitstate = state.split(",");
    localStorage.setItem("State", state);
    if (vaccinated == "Y" && lastDoseDate !== "") {
      var finallastDoseDate = moment(lastDoseDate).format("yyyy-MM-DD");
    }
    countryList.map((item)=>{
      if(item.countryCode==country){
        localStorage.setItem("CountryCode", JSON.stringify(item.id));
      }
    })
 
    stateSelect.map((item1)=>{
      if(item1[1].stateCode==splitstate[0])
      {
    
        localStorage.setItem("StateCode", JSON.stringify(item1[1].id));
        localStorage.setItem("countyTemp", JSON.stringify(countyTemp));
      }
    })
    let data = {
      ...formstate,
      //misc
      patientId: 0,
      fromWeb: true,
      //first accordion
      state: splitstate[0],
      firstName: firstName.trim().replaceAll("''","'"),
      lastName: lastName.trim().replaceAll("''","'"),
      mobileNumber: newnumber.includes("+") ? newnumber : "+" + newnumber,
      email: stateCheckedNA === true ? "N/A" : EmailAddress.trim(),
      middleName: middleName == "" ? "" : middleName.trim().replaceAll("''","'"),
      addressOne: addressOne == "" ? "" : addressOne.trim().replaceAll("''","'"),
      addressTwo:
        addressTwo == "" ? "" : addressTwo.trim().replaceAll("''","'"),
      zipCode: zipCode == "" ? "" : zipCode.trim().replaceAll("''","'"),
      city: countyTemp.length === 0 ? city.trim() : county,
      country: country,
      county: county === "" ? null : county,
      immagirationStatus: immigrationStatus ? immigrationStatus : null,
      //second accordion
      dob: finaldateDob === undefined ? null : finaldateDob,
      gender: gender,
      ethnicity: ethnicity == "" ? null : ethnicity,
      prioritizedGroup: member,
      occupation:occupation.trim().replaceAll("''","'"),
      race: racedSelect == "" ? null : racedSelect,
      sendMessageToMobile: sendMessageToMobileCheck === true ? true : false,
      //uncomment
      phoneCode: dialCode,
      pregnant: gender == "Female" ? pregnancy : "U",
      covid19VaccineType: Covid19vaccineType ? Covid19vaccineType : "",
      noOfDosesReceived: doseCount == "" ? "" : doseCount,
      lastDoseDate: null,
      receivedCovid19Vaccine: vaccinated,
      updatedIn:
        localStorage.getItem("internal") === "true"
          ? "internal"
          : localStorage.getItem("mobile") === "true"
          ? "mobile"
          : "public",
      primaryInsuranceCompanyName: null,
      primarySubscriberName: null,
      primaryDob: null,
      primaryMemberId: null,
      primaryGroupId: null,
      secondaryInfo: false,
      secondaryInsuranceCompanyName: null,
      secondarySubscriberName: null,
      secondaryDob: null,
      secondaryMemberId: null,
      secondaryGroupId: null,
      mediCarepolicyId: null,
      mediCareSubscriberName: null,
      confirmationCode: null,
      testType: "COVID-19: Send to TPHL",
      createdDate: null,
      createdTime: null,
      labSiteName: null,
      qrCodePdfUrl: null,
      qrCodeUrl: null,
      sampleIdentifier: null,
      slotDate: null,
      slotTime: null,
      street: "test",
      //
      closeContact: closeContact,
      symptomatic: symptomatic,
      symptoms:
        stateChecked === null || stateChecked.length === 0
          ? dummyarr
          : Object.keys(stateChecked).filter((e) => stateChecked[e] === true)
              .length == 0
          ? dummyarr
          : Object.keys(stateChecked)
              .filter((e) => stateChecked[e] === true)
              .map((index) =>
                index.replaceAll("_", " ").replaceAll("Fever", "Fever>100.4")
              ),
      symptomaticDate:
        selectedDateSymptom == null
          ? null
          : moment(selectedDateSymptom).format("yyyy-MM-DD"),
    };

    let get1st_data = JSON.parse(localStorage.getItem("Form1"));
    if (get1st_data) {
      var attributes = Object.keys(get1st_data);
      for (var i = 0; i < attributes.length; i++) {
        console.log("value",get1st_data[attributes[i]]);
        data[attributes[i]] = get1st_data[attributes[i]];
      }
    }
    localStorage.setItem("Form2", JSON.stringify(data));
    setIsLoaded(true);
    console.log("local", localStorage.getItem("internal"));
    if (localStorage.getItem("internal") == null) {
      let checkdata = {
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        dob: finaldateDob === undefined ? null : finaldateDob,
      };
      api.eligibleAppointment(checkdata, (res) => {
        if (res.statusCode == 200 && res.status == "Success") {
          if (res.info.createAppointment === true) {
            if (localStorage.getItem("Form1") === null) {
              if (closeContact === "Y" || symptomatic === "Y") {
                history.push({
                  pathname: "/calendlyview",
                  state: data,
                });
              } else {
                history.push({
                  pathname: "/list",
                  state: data,
                });
              }
            } else {
              history.push({
                pathname: "/calendlyview",
                state: data,
              });
            }
          } else if (res.info.createAppointment === false) {
            history.push({
              pathname: "/list",
              state: data,
            });
          }
        }
      });
    } else {
      if (localStorage.getItem("Form1") === null) {
        if (closeContact === "Y" || symptomatic === "Y") {
          history.push({
            pathname: "/calendlyview",
            state: data,
          });
        } else {
          history.push({
            pathname: "/list",
            state: data,
          });
        }
      } else {
        history.push({
          pathname: "/calendlyview",
          state: data,
        });
      }
    }

  }

  const cancelfun = () => {
    console.log("cancel clicked")
  }
  let history = useHistory();

  const resetForm = () => {
    if (localStorage.getItem("internal") == "true") {
      history.push("/?type=internal");
    } else if (localStorage.getItem("mobile") == "true") {
      history.push("/?type=mobile");
    } else {
      history.push("/");
    }
  };

  const firstNameOnchange=(e)=>{
    let value = e.target.value.replaceAll('"',"'");
    setfirstName(value.replaceAll("''","'"));
  }

  const middleNmOnchange=(e)=>{
    let value = e.target.value.replaceAll('"',"'");
    setmiddleName(value.replaceAll("''","'"));
  }

  const lastNmOnchange=(e)=>{
    let value = e.target.value.replaceAll('"',"'");
    setlastName(value.replaceAll("''","'"));
  }

  const addressOneChange=(e)=>{
    let value = e.target.value.replaceAll('"',"'");
    setaddressOne(value.replaceAll("''","'"));
  }
  const addressTwoChange=(e)=>{
    let value = e.target.value.replaceAll('"',"'");
    setaddressTwo(value.replaceAll("''","'"));
  }

  const occupationOnchange =(e)=>{
    let value = e.target.value.replaceAll('"',"'");
    setoccupation(value.replaceAll("''","'"));
  }

  return (
    <>
      <ToastContainer />
      <Header />
      <RegisterBanner />

      {isLoaded == true ? (
        <div className="loaderAdj">
          <CircularProgress />
        </div>
      ) : (
        <div className="accordionHolder">
          <Accordion
            square
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            className="mainer"
          >
            <div className="accordionbg">
              <div className="accSummary">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <Typography ref={myRef}>
                    General Information
                    <FormHelperText style={{ color: "red" }}>
                      <span>{errorFirstData !== "" ? errorFirstData : ""}</span>

                      <span> {errorLastData ? errorLastData : ""}</span>
                      <span> {errorMobileData ? errorMobileData : ""}</span>
                      <span> {errorEmailData ? errorEmailData : ""}</span>
                      <span> {errorAddress ? errorAddress : ""}</span>
                      <span> {errorCity ? errorCity : ""}</span>
                      <span> {errorCountry ? errorCountry : ""}</span>
                      <span> {errorZip ? errorZip : ""}</span>
                      <span> {errorState ? errorState : ""}</span>
                      <span> {errorCounty ? errorCounty : ""}</span>
                      <span>
                        {" "}
                        {immigrationStatusError ? immigrationStatusError : ""}
                      </span>
                    </FormHelperText>
                  </Typography>
                </AccordionSummary>
              </div>
            </div>
            <AccordionDetails>
              <Typography style={{ width: "100%" }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4} sm={12}>
                    <TextField
                      id="standard-basic1"
                      label="First Name "
                      autoComplete="new-password"
                      InputProps={{
                        className: classes.input,
                      }}
                      className={classes.textField}
                      required
                      onChange={firstNameOnchange}
                      name="firstName"
                      value={firstName}
                      inputProps={{ maxLength: 40 }}
                    />
                  </Grid>

                  <Grid item xs={12} md={4} sm={12}>
                    <TextField
                      id="standard-basic2"
                      label="Middle Initial"
                      InputProps={{
                        className: classes.input,
                      }}
                      className={classes.textField}
                      onChange={middleNmOnchange}
                      name="middleName"
                      autoComplete="new-password"
                      value={middleName}
                      inputProps={{ maxLength: 40 }}
                    />
                  </Grid>

                  <Grid item xs={12} md={4} sm={12}>
                    <TextField
                      id="standard-basic3"
                      label="Last Name"
                      InputProps={{
                        className: classes.input,
                      }}
                      className={classes.textField}
                      required
                      onChange={lastNmOnchange}
                      name="lastName"
                      autoComplete="new-password"
                      value={lastName}
                      inputProps={{ maxLength: 40 }}
                    />
                  </Grid>

                  <Grid item xs={12} md={12} sm={12}>
                    <FormLabel component="legend" className="paddingAdj">
                      Please share your contact information. Your email and
                      phone will also be where you will receive an invitation to
                      view your test results.
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={7} sm={12}>
                    <InputLabel
                      id="demo-simple-select-label"
                      style={{ paddingBottom: "0.3em" }}
                    >
                      Mobile Number *
                    </InputLabel>
                    <PhoneInput
                        country={"us"}
                      // onlyCountries={["us"]}
                      // disableDropdown={true}
                      onChange={mobileNumberChange}
                      value={newnumber}
                      inputProps={{
                        autoFormat: true,
                        countryCodeEditable: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={5} sm={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={sendMessageToMobileCheck}
                          onChange={handleChangeMobileCheck}
                          name="N/A"
                          value="N/A"
                        />
                      }
                      label="This number can receive text messages"
                      style={{ marginTop: "13px" }}
                    />
                  </Grid>

                  <Grid item xs={12} md={7} sm={12}>
                    <TextField
                      id="standard-basic4"
                      label="Email"
                      InputProps={{
                        className: classes.input,
                      }}
                      className={classes.textField}
                      required
                      onChange={(e)=>setEmailAddress(e.target.value)}
                      name="email"
                      autoComplete="new-password"
                      value={stateCheckedNA === true ? "N/A" : EmailAddress}
                      inputProps={{ maxLength: 150 }}
                    />
                  </Grid>

                  <Grid item xs={12} md={5} sm={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          // checked={stateChecked.Shortness_of_breath}
                          onChange={handleChangeNA}
                          name="N/A"
                          value="N/A"
                        />
                      }
                      label="N/A - Not Applicable"
                      style={{ marginTop: "13px" }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} sm={12}>
                    <TextField
                      id="standard-basic5"
                      label="Address 1"
                      InputProps={{
                        className: classes.input,
                      }}
                      className={classes.textField}
                      required
                      onChange={addressOneChange}
                      name="addressOne"
                      autoComplete="new-password"
                      value={addressOne}
                      inputProps={{ maxLength: 200 }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} sm={12}>
                    <TextField
                      id="standard-basic6"
                      label="Address 2"
                      InputProps={{
                        className: classes.input,
                      }}
                      className={classes.textField}
                      onChange={addressTwoChange}
                      name="addressTwo"
                      autoComplete="new-password"
                      value={addressTwo}
                      inputProps={{ maxLength: 200 }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} sm={12}>
                    <FormControl
                      className="form-dropdown"
                      fullWidth
                      variant="standard"
                    >
                      <InputLabel>Country*</InputLabel>
                      <Select
                        name="country"
                        value={country}
                        onChange={handleChangeCountry}
                        required
                      >
                        {countryList.map((item) => (
                          <MenuItem value={item.countryCode}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6} sm={12}>
                    <FormControl
                      className={classes.formControl}
                      style={{ width: "100%" }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        State/Territory *
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="state"
                        value={state}
                        onChange={handleChangeState}
                        required
                      >
                        {stateSelect.map((item) => (
                          <MenuItem value={item[1].stateCode + "," + item[0]}>
                            {item[0]}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} sm={12}>
                    <TextField
                      id="standard-basic8"
                      label="Zipcode/Postal Code"
                      InputProps={{
                        className: classes.input,
                      }}
                      className={classes.textField}
                      required
                      onChange={(e) =>{
                        let value = e.target.value.replaceAll('"',"'")
                        setzipCode(value.replaceAll("''","'"))
                        }}
                      name="zipCode"
                      autoComplete="new-password"
                      value={zipCode}
                      inputProps={{ maxLength: 20 }}
                    />
                  </Grid>

                  {countyTemp.length ? (
                    <Grid item xs={12} md={6} sm={12}>
                      <FormControl
                        className={classes.formControl}
                        style={{ width: "100%" }}
                      >
                        <InputLabel id="demo-simple-select-label">
                          County/District *
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label11"
                          id="demo-simple-select"
                          onChange={countyChange}
                          value={county}
                        >
                          {countyTemp !== null && countyTemp !== undefined
                            ? countyTemp.map((item) => (
                                <MenuItem value={item[0]}>{item[0]}</MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                    </Grid>
                  ) : (
                    <Grid item xs={12} md={6} sm={12}>
                      <TextField
                        id="standard-basic8"
                        InputProps={{
                          className: classes.input,
                        }}
                        className={classes.textField}
                        label="City/Estate*"
                        variant="standard"
                        name="city"
                        inputProps={{ maxLength: 40, autoComplete: "none" }}
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />
                    </Grid>
                  )}

                  {process.env.REACT_APP_LAB_ID === "71" ||
                  process.env.REACT_APP_LAB_ID === "72" ||
                  process.env.REACT_APP_LAB_ID === "73" ||
                  process.env.REACT_APP_LAB_ID === "74" ||
                  process.env.REACT_APP_LAB_ID === "75" ||
                  process.env.REACT_APP_LAB_ID === "76" ? (
                    <Grid item xs={12} md={6} sm={12}>
                      <FormControl
                        className={classes.formControl}
                        style={{ width: "100%" }}
                      >
                        <InputLabel id="demo-simple-select-label">
                          What is your immigration status? *
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label11"
                          id="demo-simple-select"
                          onChange={immigrationStatusChange}
                          value={immigrationStatus}
                        >
                          <MenuItem value={"BVIslander/Belonger"}>
                            BVIslander/Belonger
                          </MenuItem>
                          <MenuItem value={"BVI Resident"}>
                            BVI Resident
                          </MenuItem>
                          <MenuItem value={"Tourist"}>Tourist</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  ) : (
                    ""
                  )}

                  <Grid item xs={12} md={12} sm={12}>
                    <div
                      className={active1 === true ? "buttonNext" : "buttonNext"}
                    >
                      <button onClick={moveNext}>Next</button>
                    </div>
                  </Grid>
                </Grid>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            square
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <div className="accordionbg">
              <div className="accSummary">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2d-content"
                  id="panel2d-header"
                >
                  <Typography>
                    Additional Demographics
                    <FormHelperText style={{ color: "red" }}>
                      <span> {errorOccupation ? errorOccupation : ""}</span>
                      <span> {errorRace ? errorRace : ""}</span>
                      <span> {errorGender ? errorGender : ""}</span>
                      <span> {errorDateofbirth ? errorDateofbirth : ""}</span>
                      <span> {errorMember ? errorMember : ""}</span>
                      <span> {errorEthnicity ? errorEthnicity : ""}</span>
                    </FormHelperText>
                  </Typography>
                </AccordionSummary>
              </div>
            </div>
            <AccordionDetails>
              <Typography style={{ width: "100%" }}>
                <Grid container spacing={3}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid item xs={12} md={6} sm={12}>
                      <KeyboardDatePicker
                        style={{ width: "100%" }}
                        clearable
                        value={selectedDate}
                        onChange={(date) => handleDateChange(date)}
                        format="MM/dd/yyyy"
                        label={
                          <Typography variant="inherit" component="span">
                            {" "}
                            Date of Birth{" "}
                            <span className="adjs">(MM/DD/YYYY) </span>
                          </Typography>
                        }
                        maxDate={new Date()}
                        required
                        autoOk
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>

                  <Grid item xs={12} md={6} sm={12}>
                    <FormControl
                      className={classes.formControl}
                      style={{ width: "100%" }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Race *
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={racedSelect}
                        onChange={handleChangeRace}
                        name="race"
                      >
                        {raced.map((index) => (
                          <MenuItem value={index.raceName}>
                            {index.raceName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6} sm={12}>
                    <FormControl
                      className={classes.formControl}
                      style={{ width: "100%" }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Gender *
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={gender}
                        onChange={handleChangeGender}
                        required
                      >
                        <MenuItem value={"Male"}>Male</MenuItem>
                        <MenuItem value={"Female"}>Female</MenuItem>
                        <MenuItem value={"Other"}>Other</MenuItem>
                        {/* <MenuItem value={"Unknown"}>Unknown</MenuItem> */}
                        <MenuItem value={"Ambiguous"}>Non-binary</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6} sm={12} className="radioAwesome">
                    <FormControl
                      className={classes.formControl}
                      style={{ width: "100%" }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Ethnicity *
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={ethnicity}
                        onChange={handleChangeEthnicity}
                        name="ethnicity"
                      >
                        {RaceEthinicity.map((index) => (
                          <MenuItem value={index.ethinicityName}>
                            {index.ethinicityName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={12} sm={12}>
                    <FormLabel component="legend" className="paddingAdj">
                      Is the patient a member of one of these groups? *
                    </FormLabel>
                    <RadioGroup
                      aria-label="member"
                      name="member"
                      value={member}
                      onChange={handleChangeMember}
                    >
                      <Grid item xs={12} md={6} sm={12}>
                        <FormControlLabel
                          value="First response/Healthcare"
                          control={<Radio />}
                          label="First responder or Healthcare worker"
                        />
                      </Grid>
                      <Grid item xs={12} md={6} sm={12}>
                        <FormControlLabel
                          value="Congregate facility"
                          control={<Radio />}
                          label="Congregate facility worker or resident"
                        />
                      </Grid>
                      <Grid item xs={12} md={6} sm={12}>
                        <FormControlLabel
                          value="Hospitality/Transportation"
                          control={<Radio />}
                          label="Hospitality or Transportation worker"
                        />
                      </Grid>
                      <Grid item xs={12} md={6} sm={12}>
                        <FormControlLabel
                          value="In-person school/daycare"
                          control={<Radio />}
                          label="In-person school/daycare student or worker"
                        />
                      </Grid>

                      {/* <Grid item xs={12} md={6} sm={12}>
                        <FormControlLabel
                          value="Not reported"
                          control={<Radio />}
                          label="Not reported"
                        />
                      </Grid> */}

                      <Grid item xs={12} md={6} sm={12}>
                        <FormControlLabel
                          value="Not part of any listed groups"
                          control={<Radio />}
                          label="Not part of any listed groups"
                        />
                      </Grid>

                      <Grid item xs={12} md={6} sm={12}>
                        <FormControlLabel
                          value="Others"
                          control={<Radio />}
                          label="Other priority group (Please indicate below)"
                        />
                      </Grid>
                    </RadioGroup>
                  </Grid>
                  {member ? (
                    <Grid item xs={12} md={6} sm={12}>
                      <TextField
                        id="standard-basic10"
                        label="Occupation/Affiliation"
                        style={{ width: "100%" }}
                        InputProps={{
                          className: classes.input,
                        }}
                        className={classes.textField}
                        required
                        onChange={occupationOnchange}
                        name="occupation"
                        autoComplete="new-password"
                        value={occupation}
                        inputProps={{ maxLength: 100 }}
                      />
                    </Grid>
                  ) : null}
                </Grid>

                <Grid item xs={12} md={12} sm={12}>
                  <div
                    className={active2 === true ? "buttonNext" : "buttonNext"}
                  >
                    <button onClick={moveNext1}>Next</button>
                  </div>
                </Grid>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            square
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <div className="accordionbg">
              <div className="accSummary">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3d-content"
                  id="panel3d-header"
                >
                  <Typography>
                    Recent Health Updates
                    <FormHelperText style={{ color: "red" }}>
                      <span> {errorStateChecked ? errorStateChecked : ""}</span>
                      <span> {errorSymptomDate ? errorSymptomDate : ""}</span>
                      <span> {errorSymptoms ? errorSymptoms : ""}</span>
                      <span> {errorSymptomatic ? errorSymptomatic : ""}</span>
                      <span> {errorVaccinated ? errorVaccinated : ""}</span>
                      <span> {errorcloseContact ? errorcloseContact : ""}</span>

                      <span>
                        {Covid19vaccineTypeError ? Covid19vaccineTypeError : ""}
                      </span>
                      <span>{doseCountError ? doseCountError : ""}</span>
                      <span>{lastDoseDateError ? lastDoseDateError : ""}</span>
                    </FormHelperText>
                  </Typography>
                </AccordionSummary>
              </div>
            </div>
            <AccordionDetails>
              <Typography style={{ width: "100%" }}>
                {gender == "Female" ? (
                  <Grid item xs={12} md={12} sm={12}>
                    <FormLabel component="legend" className="paddingAdj">
                      Pregnancy Status
                    </FormLabel>
                    <RadioGroup
                      aria-label="pregnancy"
                      name="pregnancy"
                      value={pregnancy}
                      onChange={handleChangeFormPregnancy}
                    >
                      <FormControlLabel
                        value="Y"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="N"
                        control={<Radio />}
                        label="No"
                      />

                      <FormControlLabel
                        value="U"
                        control={<Radio />}
                        label="Unknown"
                      />
                    </RadioGroup>
                  </Grid>
                ) : null}

                {localStorage.getItem("Form1") === null ? (
                  <Grid item xs={12} md={12} sm={12}>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <Grid item xs={12} md={12} sm={12}>
                        <FormLabel component="legend" className="paddingAdj">
                          Have you been experiencing any Covid-19 symptoms? *
                        </FormLabel>
                        <RadioGroup
                          aria-label="symtomatic"
                          name="symtomatic"
                          value={symptomatic}
                          onChange={handleSymptomatic}
                        >
                          <FormControlLabel
                            value="Y"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="N"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </Grid>
                      {symptomatic === "Y" ? (
                        <FormGroup>
                          <Grid item xs={12} md={3} sm={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  defaultChecked={dummyarr.includes(
                                    "Shortness of breath"
                                  )}
                                  onChange={handleChangeCheck}
                                  name="Shortness_of_breath"
                                  value="Shortness of breath"
                                />
                              }
                              label="Shortness of breath"
                            />
                          </Grid>

                          <Grid item xs={12} md={3} sm={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  defaultChecked={dummyarr.includes("Cough")}
                                  onChange={handleChangeCheck}
                                  name="Cough"
                                  value="Cough"
                                />
                              }
                              label="Cough"
                            />
                          </Grid>

                          <Grid item xs={12} md={3} sm={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  defaultChecked={dummyarr.includes("Chills")}
                                  onChange={handleChangeCheck}
                                  name="Chills"
                                  value="Chills"
                                />
                              }
                              label="Chills"
                            />
                          </Grid>
                          <Grid item xs={12} md={3} sm={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  defaultChecked={dummyarr.includes(
                                    "Fever >100.4"
                                  )}
                                  onChange={handleChangeCheck}
                                  name="Fever"
                                  value="Fever >100.4"
                                />
                              }
                              label="Fever >100.4"
                            />
                          </Grid>

                          <Grid item xs={12} md={3} sm={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  defaultChecked={dummyarr.includes("Headache")}
                                  onChange={handleChangeCheck}
                                  name="Headache"
                                  value="Headache"
                                />
                              }
                              label="Headache"
                            />
                          </Grid>

                          <Grid item xs={12} md={3} sm={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  defaultChecked={dummyarr.includes("Fatigue")}
                                  onChange={handleChangeCheck}
                                  name="Fatigue"
                                  value="Fatigue"
                                />
                              }
                              label="Fatigue"
                            />
                          </Grid>

                          <Grid item xs={12} md={3} sm={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  defaultChecked={dummyarr.includes("Diarrhea")}
                                  onChange={handleChangeCheck}
                                  name="Diarrhea"
                                  value="Diarrhea"
                                />
                              }
                              label="Diarrhea"
                            />
                          </Grid>

                          <Grid item xs={12} md={3} sm={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  defaultChecked={dummyarr.includes("Vomiting")}
                                  onChange={handleChangeCheck}
                                  name="Vomiting"
                                  value="Vomiting"
                                />
                              }
                              label="Vomiting"
                            />
                          </Grid>

                          <Grid item xs={12} md={3} sm={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  defaultChecked={dummyarr.includes(
                                    "Sore Throat"
                                  )}
                                  onChange={handleChangeCheck}
                                  name="Sore_Throat"
                                  value="Sore Throat"
                                />
                              }
                              label="Sore Throat"
                            />
                          </Grid>

                          <Grid item xs={12} md={3} sm={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  defaultChecked={dummyarr.includes("Nausea")}
                                  onChange={handleChangeCheck}
                                  name="Nausea"
                                  value="Nausea"
                                />
                              }
                              label="Nausea"
                            />
                          </Grid>

                          <Grid item xs={12} md={6} sm={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  defaultChecked={dummyarr.includes(
                                    "Loss of taste"
                                  )}
                                  onChange={handleChangeCheck}
                                  name="Loss_of_taste"
                                  value="Loss of taste"
                                />
                              }
                              label={
                                <Typography
                                  variant="inherit"
                                  component="span"
                                  style={{ paddingTop: "1em" }}
                                >
                                  Loss of taste <br /> and / or smell{" "}
                                </Typography>
                              }
                            />
                          </Grid>
                        </FormGroup>
                      ) : null}
                    </FormControl>
                  </Grid>
                ) : (
                  ""
                )}
                {symptomatic === "Y" ? (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      sm={12}
                      style={{ marginTop: "1em" }}
                    >
                      <KeyboardDatePicker
                        clearable
                        style={{ width: "100%" }}
                        value={selectedDateSymptom}
                        onChange={(date) => handleDateChangeSymptom(date)}
                        format="MM/dd/yyyy"
                        label="Symptoms start date"
                        maxDate={new Date()}
                        autoOk
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                ) : null}

                {localStorage.getItem("Form1") === null ? (
                  <Grid item xs={12} md={12} sm={12}>
                    <FormLabel component="legend" className="paddingAdj">
                      Are you a close contact of someone who tested positive? *
                    </FormLabel>
                    <RadioGroup
                      aria-label="symtomatic"
                      name="closeContact"
                      value={closeContact}
                      onChange={handlecloseContact}
                    >
                      <FormControlLabel
                        value="Y"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="N"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </Grid>
                ) : (
                  ""
                )}

                <Grid item xs={12} md={12} sm={12}>
                  <FormLabel component="legend" className="paddingAdj">
                    Have you received the Covid-19 vaccine? *
                  </FormLabel>
                  <RadioGroup
                    aria-label="symtomatic"
                    name="symtomatic"
                    value={vaccinated}
                    onChange={handleVaccinated}
                  >
                    <FormControlLabel
                      value="Y"
                      control={<Radio />}
                      label="Yes, I received the vaccine"
                    />
                    <FormControlLabel
                      value="N"
                      control={<Radio />}
                      label="No, I have not yet received the vaccine"
                    />
                  </RadioGroup>
                </Grid>

                {vaccinated === "Y" &&
                  (process.env.REACT_APP_LAB_ID === "71" ||
                    process.env.REACT_APP_LAB_ID === "72" ||
                    process.env.REACT_APP_LAB_ID === "73" ||
                    process.env.REACT_APP_LAB_ID === "74" ||
                    process.env.REACT_APP_LAB_ID === "75" ||
                    process.env.REACT_APP_LAB_ID === "76") && (
                    <>
                      <Grid
                        style={{ marginTop: "3px" }}
                        container
                        spacing={3}
                        item
                        xs={12}
                        md={12}
                        sm={12}
                      >
                        <Grid item xs={12} md={6} sm={12}>
                          <FormControl
                            className={classes.formControl}
                            style={{ width: "100%" }}
                          >
                            <InputLabel id="demo-simple-select-label">
                              Which COVID-19 vaccine type did you receive? *
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label11"
                              id="demo-simple-select"
                              onChange={handleCovid19vaccineType}
                              value={Covid19vaccineType}
                            >
                              <MenuItem value={"Astra-Zeneca"}>
                                Astra-Zeneca
                              </MenuItem>
                              <MenuItem value={"Janssen"}>Janssen</MenuItem>
                              <MenuItem value={"Moderna"}>Moderna</MenuItem>
                              <MenuItem value={"Pfizer-BioNTech"}>
                                Pfizer-BioNTech
                              </MenuItem>
                              <MenuItem value={"Other"}>Other</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        {(Covid19vaccineType == "Astra-Zeneca" ||
                          Covid19vaccineType == "Moderna" ||
                          Covid19vaccineType == "Pfizer-BioNTech" ||
                          Covid19vaccineType == "Other") && (
                          <Grid item xs={12} md={6} sm={12}>
                            <FormLabel component="legend">
                              How many doses have you received? *
                            </FormLabel>
                            <RadioGroup
                              aria-label="dosecount"
                              name="dosecount"
                              value={doseCount}
                              onChange={handleChangedoseCount}
                            >
                              <FormControlLabel
                                value="1"
                                control={<Radio />}
                                label="One"
                              />
                              <FormControlLabel
                                value="2+"
                                control={<Radio />}
                                label="Two +"
                              />
                            </RadioGroup>
                          </Grid>
                        )}
                      </Grid>
                      <Grid container spacing={3} item xs={12} md={12} sm={12}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Grid item xs={12} md={6} sm={12}>
                            <KeyboardDatePicker
                              clearable
                              value={lastDoseDate}
                              onChange={(date) => handlelastDoseDate(date)}
                              format="dd/MM/yyyy"
                              label={
                                <Typography variant="inherit" component="span">
                                  {" "}
                                  When was your last dose date?{" "}
                                  <span className="adjs">(DD/MM/YYYY) </span>
                                </Typography>
                              }
                              maxDate={new Date()}
                              required
                              autoOk
                            />
                          </Grid>
                        </MuiPickersUtilsProvider>
                      </Grid>
                    </>
                  )}
              </Typography>
            </AccordionDetails>
          </Accordion>

          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <div>
              <button className="resetform" onClick={resetForm}>
                <span>Back</span>
              </button>
            </div>

            <div className="submit">
              <button className="btn" onClick={formSubmit}>
                <span>Continue to schedule</span>
                <span>
                  {" "}
                  <i className="fa fa-arrow-right"></i>{" "}
                </span>
              </button>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
}
