import React, { useEffect } from "react";
import { InlineWidget } from "react-calendly";
import Header from "./Header.js";
import Footer from "./Footer.js";
import "./Calendly.css";
import bannerImage from "../assets/bannerImage.png";
import { useHistory } from "react-router-dom";

export default function Form(props) {
  console.log("props received", props);
  const [firstName, setfirstname] = React.useState();
  const [lastName, setlastname] = React.useState();
  const [email, setemail] = React.useState();
  let history = useHistory();
  const handleClose = () => {
    if (localStorage.getItem("internal") == "true") {
      history.push("/form?type=internal");
    } else if (localStorage.getItem("mobile") == "true") {
      history.push("/form?type=mobile");
    } else {
      history.push("/form");
    }
  };
  // Did mount
  useEffect(() => {
    setfirstname(props.location.state.firstName);
    setlastname(props.location.state.lastName);
    setemail(props.location.state.email);
  }, []);

  function isCalendlyEvent(e) {
    return e.data.event && e.data.event.indexOf("calendly") === 0;
  }

  window.addEventListener("message", function (e) {
    if (isCalendlyEvent(e)) {
    }
  });

  return (
    <>
      <Header />
      <div className="bannerHolder">
        <div className="bannerContent">
          <h1>{process.env.REACT_APP_BANNER_NAME}</h1>
          <p className="welcomeP">
            Welcome, you can schedule your first or second dose of the Covid-19
            vaccine here.
          </p>
        </div>
        <div className="bannerImage">
          <img src={bannerImage} alt="banner" className="bannerRight" />
        </div>
      </div>
      <div>
        <h3 className="candelyhead">
          We’re almost done! Please select a day and time slot that works for
          you.
        </h3>
        <p className="btnback" onClick={handleClose}>
          <span>
            {" "}
            <i className="fa fa-arrow-left"></i>{" "}
          </span>
          <span>Go Back</span>
        </p>
        <div className="reviewAlert"  >
          Please review and click “Schedule Event” to confirm your appointment</div>
      </div>

      <div className="calendlyHolder">
        <InlineWidget
          // .env
          url={process.env.REACT_APP_CALENDLY_URL}
          prefill={{
            email:
              email == "N/A" ? "placeholder_email@hummingbird.health" : email,
            firstName: firstName,
            lastName: lastName,
          }}
          styles={{ position: "relative", minWidth: "320px", height: "650px" }}
        />
      </div>

      <Footer />
    </>
  );
}