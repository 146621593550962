import React, { useEffect } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import api from "../Service/API/index.js";
import close from "../assets/close.png";
import Modal from "@material-ui/core/Modal";
import bannerImage from "../assets/bannerImage.png";
import success from "../assets/success.png";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FileCopyIcon from "@material-ui/icons/FileCopy";
//Modal Box Material UI
function getModalStyle() {
  const top = 50;
  const left = 50;
  const height = "100vh";

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export default function Form(props) {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const [confirmationCode, setConfirmationCode] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [calendlyStartTime, setcalendlyStartTime] = React.useState("");
  const [calendlyconvertTime, setcalendlyconvertTime] = React.useState("");
  const [copied, setcopied] = React.useState(false);
  const [getpdfdownload, setpdfdownload] = React.useState("");
  const [getQRcode, setQrcode] = React.useState("");
  const [dob, setdob] = React.useState("");
  const [labSiteName, setlabSiteName] = React.useState("");
  const [getdate, setdate] = React.useState("");
  const [gettime, settime] = React.useState("");
  let history = useHistory();

  useEffect(() => {
    setIsLoaded(true);
    getAPicall();
  }, []);

  const getAPicall = () => {
    let data = JSON.parse(localStorage.getItem("Form2"))
      ? JSON.parse(localStorage.getItem("Form2"))
      : [];
      console.log("Mohan",data)

    if (data.length == 0) {
      if (localStorage.getItem("internal") == "true") {
        history.push("/?type=internal");
      } else if (localStorage.getItem("mobile") == "true") {
        history.push("/?type=mobile");
      } else {
        history.push("/");
      }
    } else {
      // //api for posting form
      let data1 = {
        calendlyAssignedTo: new URLSearchParams(window.location.search).get(
          "assigned_to"
        ),
        calendlyEventTypeUuid: new URLSearchParams(window.location.search).get(
          "event_type_uuid"
        ),
        calendlyEventTypeName: new URLSearchParams(window.location.search).get(
          "event_type_name"
        ),
        calendlyEventStartTime: new URLSearchParams(window.location.search).get(
          "event_start_time"
        ),
        calendlyEventEndTime: new URLSearchParams(window.location.search).get(
          "event_end_time"
        ),
        calendlyInviteeUuid: new URLSearchParams(window.location.search).get(
          "invitee_uuid"
        ),
      };

      var attributes = Object.keys(data);
      for (var i = 0; i < attributes.length; i++) {
        data1[attributes[i]] = data[attributes[i]];
      }
      api.vaccineSchedule(data1, (res) => {
        if (res.statusCode == 200 && res.status == "Success") {
          // localStorage.clear();
          localStorage.removeItem("Form2");
          localStorage.removeItem("Form1");

          setOpen(true);
          setIsLoaded(false);
          setdob(res.info.dob);
          setQrcode(res.info.qrCodeUrl);
          setlabSiteName(res.info.labSiteName)
          setpdfdownload(res.info.qrCodePdfUrl);
          setConfirmationCode(res.info.confirmationCode);
          setcalendlyStartTime(res.info.calendlyEventStartTime);
          var timeString = res.info.calendlyEventStartTime.slice(11, 13);
          var mins = res.info.calendlyEventStartTime.slice(13, 16);
          var H = +timeString.substr(0, 2);
          var h = H % 12 || 12;
          setdate(moment(res.info.calendlyEventStartTime).format("MM-DD-yyyy - hh:mm a"));
          settime(res.info.createdTime);
          var ampm = H < 12 ? "AM" : "PM";
          timeString = h + timeString.substr(2, 3);
          let finalTime = timeString.concat(mins) + ampm;
          setcalendlyconvertTime(finalTime);
        } else if (res.errorCode == 400) {
          setIsLoaded(false);
          alert(res.errorMessage);
          if (localStorage.getItem("internal") == "true") {
            history.push("/?type=internal");
          } else if (localStorage.getItem("mobile") == "true") {
            history.push("/?type=mobile");
          } else {
            history.push("/");
          }
        }
      });
    }
  };

  const handleClose = () => {
    if (localStorage.getItem("internal") == "true") {
      history.push("/?type=internal");
    } else if (localStorage.getItem("mobile") == "true") {
      history.push("/?type=mobile");
    } else {
      history.push("/");
    }
    localStorage.clear();
  };
  const copyclip = (copy) => {
    navigator.clipboard.writeText(copy);
    setcopied(true);
  };
  //modal box body
  const body = (

    <div style={modalStyle} className="paperModal">
      <div className="closeHolder" onClick={handleClose}>
        <img src={close} />
      </div>
      <div className="content">
        <div className="rander">
          <img src={bannerImage} className="popImage" />
        </div>
        <div className="center">
          <img src={success} className="popImage1" />
        </div>
      </div>

      <div className="successmsg">
        <div style={{ fontSize: "35px", marginTop: "15px" }}>
          Patient registered successfully
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div>
            <img src={getQRcode} style={{ width: "160px" }} />
          </div>
          <div style={{ marginTop: '120px' }}>
            <a href={getpdfdownload} target="_blank" >
              <i className="fa fa-download"></i>
            </a>
          </div>
        </div>

        <table style={{ display: "inline-grid", paddingBottom: "1.5em",width: "83%"}}>
          <tr style={{ fontSize: "20px" }}>
            <td>
              <div style={{ color: "#f07167" }}>Confirmation Code:</div>
            </td>
            <td>
              <div style={{ color: "#00afb9" }}>  {confirmationCode}</div>
            </td>
          </tr>
          <tr style={{ fontSize: "20px" }}>
            <td>
              <div style={{ color: "#f07167" }}>Name:</div>
            </td>
            <td>
              <div style={{ color: "#00afb9" }}>
                {new URLSearchParams(window.location.search).get(
                  "invitee_first_name"
                )}{" "}
                {new URLSearchParams(window.location.search).get(
                  "invitee_last_name"
                )}
              </div>
            </td>
          </tr>
          <tr style={{ fontSize: "20px" }}>
            <td>
              <div style={{ color: "#f07167" }}>DOB:</div>
            </td>
            <td>
              <div style={{ color: "#00afb9" }}>{dob}</div>
            </td>
          </tr>
          <tr style={{ fontSize: "20px" }}>
            <td>
              <div style={{ color: "#f07167" }}>Location:</div>
            </td>
            <td>
              <div style={{ color: "#00afb9" }}>{process.env.REACT_APP_LOCATION}</div>
            </td>
          </tr>
          <tr style={{ fontSize: "20px" }}>
            <td>
              <div style={{ color: "#f07167" }}>
                Appointment Date and Time(AST):
              </div>
            </td>
            <td>
              <div style={{ color: "#00afb9" }}>
              {moment(calendlyStartTime).format("MMM")}-
              {calendlyStartTime.slice(8, 10)}-
              {moment(calendlyStartTime).format("yyyy")} {calendlyconvertTime}
              {" AST "}
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>









    // -----------------------------------------------------
    // <div style={modalStyle} className="paperModal">
    //   <div className="closeHolder" onClick={handleClose}>
    //     <img src={close} />
    //   </div>
    //   <div className="content">
    //     <div className="rander">
    //       <img src={bannerImage} />
    //     </div>
    //     <div className="center">
    //       <img src={success} />
    //     </div>
    //   </div>

    //   <div className="successmsg">
    //     <div className="appcoinfirm">Covid-19 Testing Appointment Confirmation</div>
    //     <div className="confirmationCode">
    //       <div>
    //         <h2>Name:</h2>
    //       </div>
    //       <div>
    //         <h2>
    //           {new URLSearchParams(window.location.search).get(
    //             "invitee_first_name"
    //           )}{" "}
    //           {new URLSearchParams(window.location.search).get(
    //             "invitee_last_name"
    //           )}
    //         </h2>
    //       </div>
    //     </div>
    //     <div className="confirmationCode">
    //       <div>
    //         <h2>Confirmation Code:</h2>
    //       </div>
    //       <div style={{ display: "flex" }}>
    //         <h2>
    //           {confirmationCode}
    //         </h2>
    //         <span
    //           onClick={() => copyclip(confirmationCode)}
    //           style={{ padding: "23px", cursor: "pointer" }}
    //         >
    //           <i class="fa fa-clipboard" aria-hidden="true"></i>
    //         </span>
    //         {copied == true ? (
    //           <div style={{ color: "red", marginTop: "22px" }}>Copied</div>
    //         ) : null}
    //       </div>
    //     </div>

    //     <div className="confirmationCode">
    //       <div>
    //         <h2>Date/Time:</h2>
    //       </div>
    //       <div>
    //         <h2 className="dateH2">
    //           {moment(calendlyStartTime).format("MMM")}-
    //           {calendlyStartTime.slice(8, 10)}-
    //           {moment(calendlyStartTime).format("yyyy")} {calendlyconvertTime}
    //           {" AST "}
    //         </h2>
    //       </div>
    //     </div>

    //     <div className="confirmationCode">
    //       <div>
    //         <h2>Location:</h2>
    //       </div>
    //       <div style={{ textAlign: "initial" }}>
    //         <h2>
    //           {localStorage.getItem("selectcvcmonth") == "may"
    //             ? process.env.REACT_APP_LOCATION_MAY
    //             : process.env.REACT_APP_LOCATION}
    //         </h2>
    //       </div>
    //     </div>
    //     {localStorage.getItem('getAgelimit') == "true" ?
    //       <div className="confirmationCode">
    //         <div>
    //           <h2>Adolescents ages 12-15 must have their birth certificate on hand and be accompanied with a parent or guardian with ID.</h2>
    //         </div>

    //       </div>
    //       : ""
    //     }
    //   </div>
    // </div>
  );

  return (
    <>
      <Header />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
      <div className="">
        {isLoaded == true ? (
          <div className="loaderAdj">
            <CircularProgress />
            <div style={{ height: "70vh" }}></div>
          </div>
        ) : (
          <div style={{ height: "100vh" }}></div>
        )}
      </div>

      <Footer />
    </>
  );
}
