import React from 'react';
import bannerImage from "../assets/bannerImage.png"


export default function RegisterBanner() {
    return (
        <>
            <div className="bannerHolder">

            <div className="bannerContent">
          <h1>{process.env.REACT_APP_BANNER_NAME}</h1>
          <p className="welcomeP">Welcome, you can schedule your COVID-19 test here.</p>
        </div>
                <div className="bannerImage">
                    <img src={bannerImage} alt="banner" className="bannerRight" />
                </div>
            </div>

        </>
    )
}
