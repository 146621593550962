import React, { useEffect, useRef } from "react";
import axios from "axios";
import "date-fns";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { useHistory } from "react-router-dom";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import "./Form.css";
import Modal from "@material-ui/core/Modal";
import close from "../assets/close.png";
import CircularProgress from "@material-ui/core/CircularProgress";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./Header.js";
import Footer from "./Footer.js";
import RegisterBanner from "./RegisterBanner.js";
import { FormGroup } from '@material-ui/core';
import {
    Table,
    TableContainer,
    TableBody,
    Paper,
    TableHead,
    TableRow,
    TableCell,
    TableSortLabel,
    TablePagination,
} from "@material-ui/core";
//Modal Box Material UI
function getModalStyle() {
    const top = 50;
    const left = 50;
    const height = "100vh";

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}
// Material UI Global Styles
const useStyles = makeStyles((theme) => ({
    textField: {
        width: "100%",
    },
    input: {
        color: "#00AFB9",
    },
    paper: {
        position: "absolute",

        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        borderRadius: "20px",
    },
}));

//Material UI - Accordion styles
const Accordion = withStyles({
    root: {
        margin: "2em",
        boxShadow: "none",
        "&:not(:last-child)": {
            borderBottom: 0,
        },
        "&:before": {
            display: "none",
        },
        "&$expanded": {
            margin: "2em",
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: "#fff",
        color: "#00AFB9",
        boxShadow: "0px 4px 9px rgba(0, 0, 0, 0.25)",
        borderRadius: "10px",
        marginBottom: -1,
        minHeight: 56,
        "&$expanded": {
            minHeight: 56,
        },
    },
    content: {
        "&$expanded": {
            margin: "12px 0",
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

export default function Listpage() {
    let history = useHistory();
    const myRef = useRef(null);
    const [isLoaded, setIsLoaded] = React.useState(false);

    const [expanded, setExpanded] = React.useState("panel1");
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const Backbtn = () => {
        localStorage.clear();
        history.push('/form')
    }
    // Did mount
    useEffect(() => {

    }, []);

    return (
        <>
            <ToastContainer />
            <Header />
            <RegisterBanner />
            {isLoaded == true ? (
                <div className="loaderAdj">
                    <CircularProgress />
                </div>
            ) : (
                <div className="accordionHolder">
                    {/* <h3 style={{ padding: "0px 23px 0px 32px" }}>
                        Please book only one appointment per person.
                    </h3> */}
                    <Accordion
                        square
                        expanded={expanded === "panel1"}
                        onChange={handleChange("panel1")}
                        className="mainer"
                    >
                        <div className="accordionbg">
                            <div className="accSummary">
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1d-content"
                                    id="panel1d-header"
                                >
                                    <Typography ref={myRef}>
                                    Please reach out to local providers to schedule a COVID-19 test
                                    </Typography>
                                </AccordionSummary>
                            </div>
                        </div>
                        <AccordionDetails>
                            <Typography style={{ width: "100%" }}>
                                <Grid container spacing={3}>
                                    <Grid item md={8}>
                                        {process.env.REACT_APP_LAB_ID === "15" ?
                                            <div>
                                                <div className="addressDiv" style={{ marginLeft: '5px' }}>
                                                    <div className="addressDiv2" style={{ marginTop: '15px' }}>Community Medical Laboratory</div>
                                                    <div className="addressDiv2">9149 Estates Thomas Suite 102, St. Thomas, VI 00802 </div>

                                                    <div className="addressDiv2">Phone: (340) 776-7444 </div>
                                                </div>
                                                <div style={{ textAlign: 'center' }}>(OR)</div>
                                                <div className="addressDiv" style={{ marginLeft: '5px' }}>
                                                    <div className="addressDiv2">Red Hook Family Practice</div>
                                                    <div className="addressDiv2">6500 Red Hook Plaza, Suite 205, St. Thomas, VI 00802 </div>
                                                    <div className="addressDiv2">Phone: (340) 775-2303 </div>
                                                </div>
                                            </div>
                                            : process.env.REACT_APP_LAB_ID === "14" ?
                                                <div>
                                                    <div className="addressDiv">
                                                        <div className="addressDiv2">Clinical Laboratory</div>
                                                        <div className="addressDiv2">Island Med Ctr Suite 6 Sunny Isle, St. Croix, VI 00820 </div>
                                                        <div className="addressDiv2">Phone: (340) 778-5369 </div>
                                                    </div>
                                                </div>
                                                : ""
                                        }
                                    </Grid>

                                </Grid>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <div style={{ display: "flex", justifyContent: "space-evenly",marginTop:'70px', marginBottom: '90px' }}>
                        <div>
                            <button className="resetform"
                                onClick={Backbtn}
                            >
                                <span>Back</span>
                            </button>
                        </div>
                    </div>

                </div>
            )
            }
            <Footer style={{ paddingTop: '20%' }} />
        </>
    );
}
