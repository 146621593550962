import React, { useEffect, useRef } from "react";
import axios from "axios";
import "date-fns";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { useHistory } from "react-router-dom";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import "./Form.css";
import Modal from "@material-ui/core/Modal";
import close from "../assets/close.png";
import CircularProgress from "@material-ui/core/CircularProgress";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./Header.js";
import Footer from "./Footer.js";
import RegisterBanner from "./RegisterBanner.js";
import { FormGroup } from '@material-ui/core';
import {
  Table,
  TableContainer,
  TableBody,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TablePagination,
} from "@material-ui/core";
//Modal Box Material UI
function getModalStyle() {
  const top = 50;
  const left = 50;
  const height = "100vh";

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
// Material UI Global Styles
const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
  },
  input: {
    color: "#00AFB9",
  },
  paper: {
    position: "absolute",

    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: "20px",
  },
}));

//Material UI - Accordion styles
const Accordion = withStyles({
  root: {
    margin: "2em",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "2em",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "#fff",
    color: "#00AFB9",
    boxShadow: "0px 4px 9px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export default function Form() {

  //reference for scrolling
  const myRef = useRef(null);

  //State for race
  const [raced, setRaced] = React.useState([]);
  //loading state
  const [isLoaded, setIsLoaded] = React.useState(false);

  //confirmation code state
  const [confirmationCode, setConfirmationCode] = React.useState("");
  //Temporary state for counties
  const [countyTemp, setCountyTemp] = React.useState([]);

  const [stateSelect, setstateSelect] = React.useState([]);
  const [calendlyStartTime, setcalendlyStartTime] = React.useState("");
  const [getqueryString, CheckqueryString] = React.useState("");
  const [closeContact, setcloseContact] = React.useState("");
  const handlecloseContact = (event) => {
    setcloseContact(event.target.value);
  };
  // api calling for Race
  const getRaces = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "/web/dropdown/list", {
        headers: {
          Authorization: process.env.REACT_APP_ACCESS_TOKEN,
        },
      })
      .then((res) => {
        setRaced(res.data.info.races);

        setCountyTemp(res.data.info.counties["Virgin Islands"]);
        setstateSelect(Object.entries(res.data.info.states));
      });
  };

  const classes = useStyles();

  //Form Initialization
  const initialFormData = Object.freeze({
    //first accordion
    firstName: new URLSearchParams(window.location.search).get(
      "invitee_first_name"
    )
      ? new URLSearchParams(window.location.search).get("invitee_first_name")
      : "",
    middleName: "",
    lastName: new URLSearchParams(window.location.search).get(
      "invitee_last_name"
    )
      ? new URLSearchParams(window.location.search).get("invitee_last_name")
      : "",
    email: new URLSearchParams(window.location.search).get("invitee_email")
      ? new URLSearchParams(window.location.search).get("invitee_email")
      : "",
    addressOne: "",
    addressTwo: "",
    city: "",
    zipCode: "",
    comments: "",
    //second accordion

    occupation: "",
    // Third
    describeDisablity: "",
  });

  // form state

  const [formstate, setformState] = React.useState(initialFormData);

  const [getAgelimit, setAgelimit] = React.useState(false)
  //Panel state - Accordion
  const [expanded, setExpanded] = React.useState("panel1");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  //move panel
  const [active1, setActive1] = React.useState(false);
  const [active2, setActive2] = React.useState(false);

  //symptom date state and Handle change
  const [selectedDateSymptom, setSelectedDateSymptom] = React.useState(null);

  const handleDateChangeSymptom = (date) => {
    setSelectedDateSymptom(date);
  };


  //checkbox symptom initialization

  const initialFormData1 = Object({
    Shortness_of_breath: false,
    Cough: false,
    Chills: false,
    Fever: false,
    Headache: false,
    Fatigue: false,
    Diarrhea: false,
    Vomiting: false,
    Sore_Throat: false,
    Nausea: false,
    Loss_of_taste: false,
  });

  //Checkbox symptom state and Handle Change

  const [stateChecked, setStateCheck] = React.useState(initialFormData1);
  const [dummyarr, setdummyarr] = React.useState([]);

  const handleChangeCheck = (event) => {
    setStateCheck({
      ...stateChecked,
      [event.target.name]: event.target.checked,
    });
  };

  // pregnancy radio state and handle change
  const [pregnancy, setPregnancy] = React.useState("U");
  const handleChangeFormPregnancy = (event) => {
    setPregnancy(event.target.value);
  };
  // Whole form Textfield Handle Change event
  const handleChangeForm = (event) => {
    const name = event.target.name;
    setformState({
      ...formstate,
      [name]: event.target.value,
    });
  };

  // Mobile Number Handle Change
  const [newnumber, setNumber] = React.useState("");

  const mobileNumberChange = (number) => {
    setNumber(number);
  };

  //Gender state and select Handle Change
  const [gender, setGender] = React.useState("");

  const handleChangeGender = (event) => {
    setGender(event.target.value);
  };

  //state select
  const [state, setState] = React.useState("VI,Virgin Islands");

  // Load county based on state
  const handleChangeState = (event) => {
    let check1 = event.target.value.split(",");

    setState(event.target.value);

    axios
      .get(process.env.REACT_APP_API_URL + "/web/dropdown/list", {
        headers: {
          Authorization: process.env.REACT_APP_ACCESS_TOKEN,
        },
      })
      .then((res) => {
        setCountyTemp(res.data.info.counties[check1[1].toString()]);
      });
  };

  //County State and Handle Change
  const [county, setCounty] = React.useState(process.env.REACT_APP_COUNTY_NAME);

  const countyChange = (event) => {
    setCounty(event.target.value);
  };

  //Ethnicity radio
  const [ethnicity, setEthnicity] = React.useState("");
  const handleChangeEthnicity = (event) => {
    setEthnicity(event.target.value);
  };
  // disability
  const [disability, setdisability] = React.useState("");
  const handleChangedisability = (event) => {
    setdisability(event.target.value);
  };
  //memberGroup state and Handle Change
  const [member, setMember] = React.useState("");
  const [vaccinedose, Setvaccinedose] = React.useState("");
  const [selectcvcmonth, setselectcvcmonth] = React.useState("");
  const CheckhandleCVC = (event) => {
    setselectcvcmonth(event.target.value);
  };
  const handleChangeMember = (event) => {
    setMember(event.target.value);
  };

  // check query string for public
  const [Testingsymptoms_Check, Setsymptoms_Check] = React.useState("");
  const Checkhandlevaccinedose = (event) => {
    Setsymptoms_Check(event.target.value);
    Setvaccinedose(event.target.value);
  };
  //date of birth date state and Handle change
  const [selectedDate, setSelectedDate] = React.useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  //Not Applicable state and Handle Change

  const [stateCheckedNA, setStateCheckNA] = React.useState(false);

  const handleChangeNA = (event) => {
    setStateCheckNA(event.target.checked);
  };

  //send mobile chekcox

  const [
    sendMessageToMobileCheck,
    setsendMessageToMobileCheck,
  ] = React.useState(true);

  const handleChangeMobileCheck = (event) => {
    setsendMessageToMobileCheck(event.target.checked);
  };

  // vaccine type select
  const [VaccineType, setVaccineType] = React.useState("");
  const handleChangeVaccineType = (event) => {
    setVaccineType(event.target.value);
  };
  //race handle change and state
  const [racedSelect, setRacedSelect] = React.useState("");

  const handleChangeRace = (event) => {
    setRacedSelect(event.target.value);
  };

  //modal box open state
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  //modal box close
  let history = useHistory();
  const handleClose = () => {
    localStorage.clear();
    setOpen(false);
    window.location.reload();
  };

  //Error States
  const [errorupdateSymptomDate, seterrorupdateSymptomDate] = React.useState();
  const [errorupdateSymptoms, seterrorupdateSymptoms] = React.useState();
  const [errorCheckvaccinedose, SeterrorCheckvaccinedose] = React.useState();
  const [errorcloseContact, SeterrorcloseContact] = React.useState();

  const [
    errordescribedisability,
    updateErrordescribeDisablity,
  ] = React.useState();

  const resetForm = () => {
    localStorage.clear();
    window.location.reload();
  };

  const formSubmit = (e) => {
    console.log("stateChecked", stateChecked)
    if (Testingsymptoms_Check != "") {
      SeterrorCheckvaccinedose("");
      myRef.current.scrollIntoView();
    }
    if (Object.keys(stateChecked).filter((e) => stateChecked[e] === true).length > 0 && selectedDateSymptom !== null
    ) {
      seterrorupdateSymptomDate("");
      myRef.current.scrollIntoView();
    }

    if (Object.keys(stateChecked).filter((e) => stateChecked[e]).length === true) {
      seterrorupdateSymptoms("");
      myRef.current.scrollIntoView();
    }
    if (closeContact != "") {
      SeterrorcloseContact("");
      myRef.current.scrollIntoView();
    }
    if (Testingsymptoms_Check === "") {
      SeterrorCheckvaccinedose("Please answer the below question")
      myRef.current.scrollIntoView();
      return;
    }

    else if (Testingsymptoms_Check === "Y" && (Object.keys(stateChecked).filter((e) => stateChecked[e]).length === true)) {
      seterrorupdateSymptoms("Please select Symptoms");
      myRef.current.scrollIntoView();
      return;
    } else if (Object.keys(stateChecked).filter((e) => stateChecked[e] === true).length > 0 && selectedDateSymptom == null) {
      seterrorupdateSymptomDate("Please select Symptoms Date");
      myRef.current.scrollIntoView();
      return;
    }
    else if (closeContact === "") {
      SeterrorcloseContact("Please answer the below question")
      myRef.current.scrollIntoView();
      return;
    }

    else {
      let data = {
        closeContact: closeContact,
        symptomatic: Testingsymptoms_Check,
        symptomaticDate: selectedDateSymptom == null ? null : moment(selectedDateSymptom).format("yyyy-MM-DD"),
        symptoms: stateChecked === null || stateChecked.length === 0 ? dummyarr : Object.keys(stateChecked).filter((e) => stateChecked[e] === true)
          .length == 0 ? dummyarr : Object.keys(stateChecked)
            .filter((e) => stateChecked[e] === true)
            .map((index) =>
              index.replaceAll("_", " ").replaceAll("Fever", "Fever>100.4")
            ),
      };
      localStorage.setItem("Form1", JSON.stringify(data));
      localStorage.setItem("State", state);
      localStorage.setItem(
        "internal",
        new URLSearchParams(window.location.search).get("type") == "internal"
      );
      localStorage.setItem(
        "mobile",
        new URLSearchParams(window.location.search).get("type") == "mobile"
      );
      history.push({
        pathname: "/form",
        state: data,
      });
    }


  };

  // Did mount
  useEffect(() => {
    if (new URLSearchParams(window.location.search).get("type") === null) {
      history.push({
        pathname: "/form",
      });
      localStorage.clear()
    }
    CheckqueryString(
      new URLSearchParams(window.location.search).get("type") == "internal" ||
      new URLSearchParams(window.location.search).get("type") == "mobile"
    );
    var url = window.location.search.substring(19);
    if (new URLSearchParams(window.location.search).get("id")) {
      var getQuery = new URLSearchParams(window.location.search).get("id");
      var decodeurl = JSON.parse(window.atob(getQuery));
    } else if (url) {
      var URLdecode = JSON.parse(window.atob(url));
    }
    // window.addEventListener("load", (event) => {
    //   localStorage.clear();
    // });

    getRaces();
    if (localStorage.getItem("Form1")) {
      var setlocal = JSON.parse(localStorage.getItem("Form1"));
      console.log("setlocal.symptoms", setlocal.symptoms)
      setSelectedDateSymptom(setlocal.symptomaticDate);
      Setsymptoms_Check(setlocal.symptomatic);
      setdummyarr(setlocal.symptoms);
      setStateCheck(setlocal.symptoms)
      setcloseContact(setlocal.closeContact)
    }
  }, []);
  console.log("dummyarr", dummyarr)
  return (
    <>
      <ToastContainer />
      <Header />
      <RegisterBanner />
      {isLoaded == true ? (
        <div className="loaderAdj">
          <CircularProgress />
        </div>
      ) : (
        <div className="accordionHolder">
          <h3 style={{ padding: "0px 23px 0px 32px" }}>
            Please book only one appointment per person.
          </h3>
          <Accordion
            square
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            className="mainer"
          >
            <div className="accordionbg">
              <div className="accSummary">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <Typography ref={myRef}>
                    General Information *
                    <FormHelperText style={{ color: "red" }}>
                      <span> {errorCheckvaccinedose ? errorCheckvaccinedose : ""}</span>
                      <span> {errorupdateSymptoms ? errorupdateSymptoms : ""}</span>
                      <span> {errorupdateSymptomDate ? errorupdateSymptomDate : ""}</span>
                      <span> {errorcloseContact ? errorcloseContact : ""}</span>

                      <span>
                        {" "}
                        {errordescribedisability ? errordescribedisability : ""}
                      </span>
                    </FormHelperText>
                  </Typography>
                </AccordionSummary>
              </div>
            </div>
            <AccordionDetails>
              <Typography style={{ width: "100%" }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12} sm={12}>
                    <FormLabel component="legend" className="paddingAdj">
                      Have you been experiencing any COVID-19 like symptoms or been exposed to someone who tested positive for COVID-19?
                    </FormLabel>

                    <RadioGroup
                      aria-label="vaccinedose"
                      name="vaccinedose"
                      value={Testingsymptoms_Check}
                      onChange={Checkhandlevaccinedose}
                    >
                      <FormControlLabel
                        value="Y"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="N"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>

                  </Grid>
                  {Testingsymptoms_Check === "Y" ?
                    <Grid item xs={12} md={12} sm={12}>
                      <FormControl
                        component="fieldset"
                        className={classes.formControl}
                      >
                        <FormLabel component="legend" className="paddingAdj">
                          Are you currently having any COVID-19 symptoms?
                        </FormLabel>
                        <FormGroup>
                          <Grid item xs={12} md={3} sm={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  defaultChecked={dummyarr.includes('Shortness of breath')}
                                  onChange={handleChangeCheck}
                                  name="Shortness_of_breath"
                                  value="Shortness of breath"
                                />
                              }
                              label="Shortness of breath"
                            />
                          </Grid>

                          <Grid item xs={12} md={3} sm={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  defaultChecked={dummyarr.includes('Cough')}
                                  onChange={handleChangeCheck}
                                  name="Cough"
                                  value="Cough"
                                />
                              }
                              label="Cough"
                            />
                          </Grid>

                          <Grid item xs={12} md={3} sm={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  defaultChecked={dummyarr.includes('Chills')}
                                  onChange={handleChangeCheck}
                                  name="Chills"
                                  value="Chills"
                                />
                              }
                              label="Chills"
                            />
                          </Grid>
                          <Grid item xs={12} md={3} sm={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  defaultChecked={dummyarr.includes('Fever >100.4')}
                                  onChange={handleChangeCheck}
                                  name="Fever"
                                  value="Fever >100.4"
                                />
                              }
                              label="Fever >100.4"
                            />
                          </Grid>

                          <Grid item xs={12} md={3} sm={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  defaultChecked={dummyarr.includes('Headache')}
                                  onChange={handleChangeCheck}
                                  name="Headache"
                                  value="Headache"
                                />
                              }
                              label="Headache"
                            />
                          </Grid>

                          <Grid item xs={12} md={3} sm={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  defaultChecked={dummyarr.includes('Fatigue')}
                                  onChange={handleChangeCheck}
                                  name="Fatigue"
                                  value="Fatigue"
                                />
                              }
                              label="Fatigue"
                            />
                          </Grid>

                          <Grid item xs={12} md={3} sm={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  defaultChecked={dummyarr.includes('Diarrhea')}
                                  onChange={handleChangeCheck}
                                  name="Diarrhea"
                                  value="Diarrhea"
                                />
                              }
                              label="Diarrhea"
                            />
                          </Grid>

                          <Grid item xs={12} md={3} sm={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  defaultChecked={dummyarr.includes('Vomiting')}
                                  onChange={handleChangeCheck}
                                  name="Vomiting"
                                  value="Vomiting"
                                />
                              }
                              label="Vomiting"
                            />
                          </Grid>

                          <Grid item xs={12} md={3} sm={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  defaultChecked={dummyarr.includes('Sore Throat')}
                                  onChange={handleChangeCheck}
                                  name="Sore_Throat"
                                  value="Sore Throat"
                                />
                              }
                              label="Sore Throat"
                            />
                          </Grid>

                          <Grid item xs={12} md={3} sm={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  defaultChecked={dummyarr.includes('Nausea')}
                                  onChange={handleChangeCheck}
                                  name="Nausea"
                                  value="Nausea"
                                />
                              }
                              label="Nausea"
                            />
                          </Grid>

                          <Grid item xs={12} md={6} sm={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  defaultChecked={dummyarr.includes('Loss of taste')}
                                  onChange={handleChangeCheck}
                                  name="Loss_of_taste"
                                  value="Loss of taste"
                                />
                              }
                              label={
                                <Typography
                                  variant="inherit"
                                  component="span"
                                  style={{ paddingTop: "1em" }}
                                >
                                  Loss of taste <br /> and / or smell{" "}
                                </Typography>
                              }
                            />
                          </Grid>
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    : ""
                  }

                  {Testingsymptoms_Check === "Y" ?
                    (
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          sm={12}
                          style={{ marginTop: "1em" }}
                        >
                          <KeyboardDatePicker
                            clearable
                            style={{ width: '100%' }}
                            value={selectedDateSymptom}
                            onChange={(date) => handleDateChangeSymptom(date)}
                            format="MM/dd/yyyy"
                            label="Symptoms start date (MM-DD-YYYY)"
                            maxDate={new Date()}
                            autoOk
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                    ) : null}

                  <Grid item xs={12} md={12} sm={12}>
                    <FormLabel component="legend" className="paddingAdj">
                      Are you a close contact of someone who tested positive? *
                    </FormLabel>
                    <RadioGroup
                      aria-label="symtomatic"
                      name="closeContact"
                      value={closeContact}
                      onChange={handlecloseContact}
                    >
                      <FormControlLabel
                        value="Y"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="N"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </Grid>


                  <Grid>

                    {process.env.REACT_APP_LAB_ID === "15" && closeContact === "N" && Testingsymptoms_Check === "N" ?
                      <div>
                        <div style={{ marginLeft: '15px' }}>Please reach out to local providers to schedule a COVID-19 test: </div>
                        <div className="addressDiv" style={{ marginLeft: '5px' }}>
                          <div className="addressDiv2" style={{ marginTop: '15px' }}>Community Medical Laboratory</div>
                          <div className="addressDiv2">9149 Estates Thomas Suite 102, St. Thomas, VI 00802 </div>

                          <div className="addressDiv2">Phone: (340) 776-7444 </div>
                        </div>
                        <div style={{ textAlign: 'center' }}>(OR)</div>
                        <div className="addressDiv" style={{ marginLeft: '5px' }}>
                          <div className="addressDiv2">Red Hook Family Practice</div>
                          <div className="addressDiv2">6500 Red Hook Plaza, Suite 205, St. Thomas, VI 00802 </div>
                          <div className="addressDiv2">Phone: (340) 775-2303 </div>
                        </div>
                      </div>
                      : process.env.REACT_APP_LAB_ID === "14" && closeContact === "N" && Testingsymptoms_Check === "N" ?
                        <div>
                          <div style={{ marginLeft: '10px', marginBottom: '10px' }}>Please reach out to local providers to schedule a COVID-19 test: </div>
                          <div className="addressDiv">
                            <div className="addressDiv2">Clinical Laboratory</div>
                            <div className="addressDiv2">Island Med Ctr Suite 6 Sunny Isle, St. Croix, VI 00820 </div>
                            <div className="addressDiv2">Phone: (340) 778-5369 </div>
                          </div>
                        </div>
                        : ""
                    }
                  </Grid>

                  {/* <Grid>
                    {process.env.REACT_APP_LAB_ID === "15" && (Testingsymptoms_Check === "Yes") ?
                      <div><div className="addressDiv1">
                        Drive-through locations:
                      </div>
                        <div>
                          <div className="addressDiv2">Schneider Regional Medical Center, 9048 Sugar Estate, St. Thomas, VI 00802 </div>
                          <div className="addressDiv2">Phone: (340) 776-8311</div>
                        </div>
                      </div>

                      : process.env.REACT_APP_LAB_ID === "14" && (Testingsymptoms_Check === "Yes") ?
                        <div>
                          <div className="addressDiv1">
                            Drive-through locations:
                          </div>
                          <div >
                            <div className="addressDiv2">Charles Harwood Complex, 3500 Est., East St, Christiansted, VI 00820 </div>
                          </div>
                        </div>

                        : ""
                    }
                  </Grid> */}
                </Grid>
              </Typography>
            </AccordionDetails>
          </Accordion>

          {Testingsymptoms_Check === "Y" || Testingsymptoms_Check === "" || closeContact === "Y" || closeContact === "" ?

            <div style={{ textAlign: 'center', marginBottom: '20px' }} >“By clicking "Next" - I acknowledge that I have answered these questions truthfully to the best of my knowledge.”

            </div>
            : ""
          }
          <div style={Testingsymptoms_Check === "N" && closeContact === "N" ? { display: "flex", justifyContent: "space-evenly", marginBottom: '25px' } : { display: "flex", justifyContent: "space-evenly" }}>
            <div>
              <button className="resetform" onClick={resetForm}>
                <span>Reset</span>
              </button>
            </div>
            {Testingsymptoms_Check === "Y" || Testingsymptoms_Check === "" || closeContact === "Y" || closeContact === "" ?
              <div className="submit">
                <button className="btn" onClick={formSubmit}>
                  <span>Next</span>
                  <span>
                    {" "}
                    <i className="fa fa-arrow-right"></i>{" "}
                  </span>
                </button>
              </div>
              : ""
            }
          </div>

        </div>
      )
      }
      <Footer />
    </>
  );
}
